/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/member-ordering */
import { LoadingController } from '@ionic/angular';
import { Injectable, NgZone } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { environment } from './../../environments/environment';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

declare let logWriter: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private getTileItemsSubject = new Subject<void>();
  private getAnswersListSubject = new Subject<void>();
  HideTakeATestPWAMenu: boolean;
  triggerGetTileItems() {
    this.getTileItemsSubject.next();
  }
  onGetTileItems() {
    return this.getTileItemsSubject.asObservable();
  }
  triggerGetAnswersList() {
    this.getAnswersListSubject.next();
  }
  onGetAnswersList() {
    return this.getAnswersListSubject.asObservable();
  }


  CustomerName: string = environment.appName;


  apiURL = '';
  ssoApiURL = '';
  appURL = '';
  version = '';
  accessKey = '';
  interval = '';
  DomainName: string;
  DomainLogo: any = '';
  parentDomainLogo: any = '';
  hasParentDomain = false;
  showContentDomainLogo = false;
  alert: any;
  loading: any;
  level: string;
  func: string;
  message: string;
  locales: any = [];
  currentRoute: any;
  isLoading = false;
  alertPresented = false;
  enableVinScan = true;
  enableQRCodeScan = true;
  enableRecentList = true;
  enableSearchKeyword = true;
  enableQuestionaire = false;
  enableWriteTag = true;
  enableCheckLists = false;
  enableItemMaintenanceButton = true;
  messageUnseenCount = 0;
  messaging: any;
  private audio = new Audio();
  checkBackURL = false;
  timeOut: any;
  messageCountTimer: any;
  previousURL = '';
  public firebaseConfig: any;
  enableMSLogin = false;
  enableGoogleLogin = false;
  enableDefaultLogin = false;
  enableMMLogin = false;
  enableReportIncident = false;
  enableMessages = false;
  enableIFramePageURL = false;
  IFramePageURL: any = '';
  enableMenuOpenInfoBlockURL = false;
  urlOfInfoBlock: any = '';
  enableInfoBlock = true;
  enableTakeTest = false;
  enableEmailLogin = false;
  lang = '1043';
  otpCodePattern = /^[0-9]{6}/;
  showQrScanner = true;
  secretKey: string = null;
  tempUserName: any = null;
  questionnaireCount: Number = 1;
  phoneCallToLinkMobile = false;
  domainColor = '';
  hideLogoutButton = true;
  enableScorm = false;
  answerLocaleId = '';
  scormLocked = '';
  answerData = '';
  localeLength = '';
  referenceUser: string;
  configDomain: any;
  firstName: any;
  lastName: any;
  showSideMenu = true;
  showCustomMenuButton = true;
  HideChangeDomainLogin: any;

  loadingCounter = 0;
  activeElement: any;

  constructor(public loadingController: LoadingController, public alertController: AlertController, private ngZone: NgZone, public modalController: ModalController) {
    this.alertPresented = false;
    if (localStorage.enableMSLogin && localStorage.enableMSLogin != '' && localStorage.enableMSLogin != 'undefined' && localStorage.enableMSLogin != 'null' && localStorage.enableMSLogin != 'false') {
      this.enableMSLogin = true;
    }
    if (localStorage.enableGoogleLogin && localStorage.enableGoogleLogin != '' && localStorage.enableGoogleLogin != 'undefined' && localStorage.enableGoogleLogin != 'null' && localStorage.enableGoogleLogin != 'false') {
      this.enableGoogleLogin = true;
    }
    if (localStorage.enableDefaultLogin && localStorage.enableDefaultLogin != '' && localStorage.enableDefaultLogin != 'undefined' && localStorage.enableDefaultLogin != 'null' && localStorage.enableDefaultLogin != 'false') {
      this.enableDefaultLogin = true;
    }
    if (localStorage.enableMMLogin && localStorage.enableMMLogin != '' && localStorage.enableMMLogin != 'undefined' && localStorage.enableMMLogin != 'null' && localStorage.enableMMLogin != 'false') {
      this.enableMMLogin = true;
    }

  }

  presentLoading() {
    this.ngZone.run(() => this.isLoading = true);
    this.activeElement=<HTMLElement> document.activeElement;
    this.activeElement?.blur();
  }

  dismiss() {
    this.ngZone.run(() => this.isLoading = false);
    this.activeElement?.focus();
  }

  async presentAlert(header, messsage) {
    if (!this.alertPresented) {
      this.alertPresented = true;
      this.alert = await this.alertController.create({
        header,
        message: messsage,
        buttons: [{
          text: 'Ok',
          handler: () => {
            this.alertPresented = false;
          }
        }],
        backdropDismiss: false
      });
      await this.alert.present();
    }
  }

  log(level: string, func: string, message: string) {
    logWriter.log(level, func, message);
  }

  translate(labelText) {
    try {
      const text = JSON.parse(localStorage.translations)[labelText];
      return text ? text : labelText;
    } catch (error) {
      return labelText;
    }
  }

  startTimer(status) {
    if (status == 'receiving') {
      this.audio.src = '../assets/videoCallRing.mp3';
    } else {
      this.audio.src = '../assets/ringing.mp3';
    }
    this.audio.load();

    const promise = this.audio.play();
    if (promise) {
      //Older browsers may not return a promise, according to the MDN website
      promise.catch(function(error) {
        console.error(error);
      });
    }
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      localStorage.isTimerStop = true;
      this.audio.pause();
    }, 30000);
  }

  pauseTimer() {
    this.audio.pause();
  }

  mute(flag) {
    if (flag) {
      this.audio.pause();
    } else {
      this.audio.play();
    }
  }


  startLoading() {
    if (this.loadingCounter == 0) {
      this.presentLoading();
    }
    this.loadingCounter++;

  }

  dismissLoading(resetCounter = false) {
    if(resetCounter){
      this.dismiss();
      this.loadingCounter = 0 ;
    } else {
      if (this.loadingCounter == 1) {
        this.dismiss();
      }
      this.loadingCounter--;
    }
  }

  clearSettings(preserveExternalLink) {
    console.log('clearing localstorage !!',`preservedExternalLink: ${preserveExternalLink}`);
    const apiURL = localStorage.apiURL ? localStorage.apiURL : '';
    const ssoApiURL = localStorage.ssoApiURL ? localStorage.ssoApiURL : '';
    const translations = localStorage.translations ? localStorage.translations : '';
    const firstLogIn = localStorage.firstLogIn ? localStorage.firstLogIn : '';
    const domain = localStorage.domain ? localStorage.domain : '';
    const domainColor = localStorage.domainColor ? localStorage.domainColor : '';
    const ImageData = localStorage.ImageData ? localStorage.ImageData : '';
    const parentDomainLogoData = localStorage.parentDomainLogoData ? localStorage.parentDomainLogoData : '';
    const showContentDomainLogo = localStorage.showContentDomainLogo ? localStorage.showContentDomainLogo : '';
    const externalLink = localStorage.externalLink ? localStorage.externalLink : '';
    const externalURL = localStorage.externalURL ? localStorage.externalURL : '';
    const enableGoogleLogin = localStorage.enableGoogleLogin;
    const enableMSLogin = localStorage.enableMSLogin;
    const enableMMLogin = localStorage.enableMMLogin;
    const enableDefaultLogin = localStorage.enableDefaultLogin;
    const appVersion = localStorage.appVersion ? localStorage.appVersion : '';
    const token = localStorage.token ? localStorage.token : '';
    const defaultColor = localStorage.defaultColor ? localStorage.defaultColor : '';
    const domainLocales = localStorage.locales ? localStorage.locales : '';
    const lang = localStorage.locale ? localStorage.locale : '';
    const ssoData = localStorage.ssoData ? JSON.parse(localStorage.ssoData) : [];
    const pwaUrl = localStorage.pwaUrl ? localStorage.pwaUrl : '';
    const hideChangeDomainLogin = localStorage.HideChangeDomainLogin;
    const notifyPermission = localStorage.notifyPermission ? localStorage.notifyPermission : '';
    
    localStorage.clear();
    if (apiURL) {localStorage.apiURL = apiURL;}
    if (ssoApiURL) {localStorage.ssoApiURL = ssoApiURL;}
    if (translations) {localStorage.translations = translations;}
    if (firstLogIn) {localStorage.firstLogIn = firstLogIn;}
    if (domain) {localStorage.domain = domain;}
    if (domainColor) {localStorage.domainColor = domainColor;}
    if (ImageData) {localStorage.ImageData = ImageData;}
    if (parentDomainLogoData) {localStorage.parentDomainLogoData = parentDomainLogoData;}
    if (showContentDomainLogo) {localStorage.showContentDomainLogo = showContentDomainLogo;}
    if(hideChangeDomainLogin) {localStorage.HideChangeDomainLogin = hideChangeDomainLogin;}
    if(notifyPermission) {localStorage.notifyPermission=notifyPermission}




    if (preserveExternalLink) {
      if (externalLink) {
        localStorage.externalLink = externalLink;
      }

      if (externalURL) {
        localStorage.externalURL = externalURL;
      }
    }

    if (enableGoogleLogin) {localStorage.enableGoogleLogin = enableGoogleLogin;}
    if (enableMSLogin) {localStorage.enableMSLogin = enableMSLogin;}
    if (enableMMLogin) {localStorage.enableMMLogin = enableMMLogin;}
    if (enableDefaultLogin) {localStorage.enableDefaultLogin = enableDefaultLogin;}
    if (appVersion) {localStorage.appVersion = appVersion;}
    if (token) {localStorage.token = token;}
    if (defaultColor) {localStorage.defaultColor = defaultColor;}
    if (lang) {localStorage.locale = lang;}
    if (ssoData.length > 0) {localStorage.ssoData = JSON.stringify(ssoData);}
    if (pwaUrl) {localStorage.pwaUrl = pwaUrl;}
    if (domainLocales) {
      localStorage.locales = domainLocales;
    }
    clearInterval(this.messageCountTimer);
    clearTimeout(this.timeOut);
    this.previousURL = '';
  }

  async isNotificationPermissions() {
    if (navigator && navigator.permissions) {
      try {
        const result = await navigator.permissions.query({ name: 'notifications' });
        if (result) {
          if (result.state === 'granted') {
            return true;
          } else {
            if(Notification){
              Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                  console.log('Notification permission granted.');
                  return true;
                } else {
                  return false;
                }
              });
            }
          }
        }
      } catch (e) {
        return true;
      }
    }
    return false;
  }




  toggleMenu(){
    this.showSideMenu = !this.showSideMenu;
  }

  setConfigValue(data) {
    this.apiURL = data.apiURL;
    this.ssoApiURL = data.ssoApiURL;
    this.appURL = data.appURL;
    this.version = data.version;
    this.accessKey = data.accessKey;
    this.interval = data.interval;
    this.CustomerName = data.appName;
    this.firebaseConfig = data.firebaseConfig;
    if (localStorage.apiURL && localStorage.apiURL != 'undefined' && localStorage.apiURL == '') {
      this.apiURL = localStorage.apiURL;
    }
    if (localStorage.ssoApiURL && localStorage.ssoApiURL != 'undefined' && localStorage.ssoApiURL == '') {
      this.ssoApiURL = localStorage.ssoUrl;
    }
    if (localStorage.appURL && localStorage.appURL != 'undefined' && localStorage.appURL == '') {
      this.appURL = localStorage.appURL;
    }
  }


  findElement(arr, key, keyValue) {
    const matches = [];

    arr.forEach(i => {
      if (keyValue.split('/').length > 3) {
        keyValue = keyValue.split('/')[0] + '/' + keyValue.split('/')[1] + '/' + keyValue.split('/')[2];
      }
      console.log('keyValue', keyValue);
      const routeUrl = i[key].toLowerCase().replace('/', '');
      console.log('routeUrl', routeUrl);
      if (routeUrl != '' && keyValue.includes(routeUrl)) {
        matches.push(i);
      }
    });

    if (keyValue.includes('item') || keyValue.includes('answer') || keyValue.includes('checkList') || keyValue.includes('my-results')) {
      matches.push(keyValue);
    }
    return matches;
  }
  clearSupportUserData() {
    localStorage.removeItem('supportuserId');
    localStorage.removeItem('previousUserName');
    localStorage.removeItem('authenticationKey');
    localStorage.removeItem('domain');
    localStorage.removeItem('domainId');
    localStorage.removeItem('locales');
    localStorage.removeItem('locale');
    localStorage.removeItem('pwaUrl');
    localStorage.removeItem('ssoData');
    localStorage.removeItem('ssoType');
  }
}
