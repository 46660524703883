import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.page.html',
  styleUrls: ['./message-modal.page.scss'],
})
export class MessageModalPage implements OnInit {
  langArray = ["label-noTimeToAnswer"];
  messageData: any = {};
  translatedObj: any;
  messageTitle: string;

  constructor(private _generalService: GeneralService,
    public global: GlobalService,
    private _translate: TranslateService,
    private navParams: NavParams,
    private modalController: ModalController,) {
    this._translate.onLangChange.subscribe((event) => {
      this._translate.get(this.langArray).subscribe((res: string) => {
        this.translatedObj = res;
      });
    });
  }

  ngOnInit() {
    this.messageData = this.navParams.data.params;
    this.messageTitle = this.messageData.Body;
  }

  async updateReadStatus(id) {
    try {
      let data = await this._generalService.updateMessage(id);
      this._generalService.getMessageCount().then(
        (data) => {
          if (data) {
            localStorage.setItem('messageUnseenCount', data.count ? data.count : 0);
            this.global.messageUnseenCount = data.count ? data.count : 0
          }
        }
      )
    } catch (err) {
      //this.global.log('ERROR', 'Message', 'Read status not updated');
    }
  }

  async closeModal() {
    const onClosedData: string = "Done";
    this.modalController.dismiss(onClosedData);
  }

}
