import { Component, OnInit, Input, EventEmitter ,Output } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-rating-modal',
  templateUrl: './rating-modal.page.html',
  styleUrls: ['./rating-modal.page.scss'],
})
export class RatingModalPage implements OnInit {
  langArray = ["label-pleaseWait", "label-noInternet", "label-errorMessage"];
  answerId: any = "";
  questionId: any = "";
  remarks: string = "";
  translatedObj: any;
  isDisabled: boolean = true;
  process: string;
  apiCallInProgress=false
  @Input() rating: number = 0;

  @Output() ratingChange: EventEmitter<number> = new EventEmitter();
  
  constructor(
    private _generalService: GeneralService,
    public global: GlobalService,
    private _translate: TranslateService,
    private modalController: ModalController,
    private navParams: NavParams) {
      this._translate.onLangChange.subscribe((event) => {
        this._translate.get(this.langArray).subscribe((res: string) => {
          this.translatedObj = res;
        });        
      });
     }

  ngOnInit() {
    this._translate.get(this.langArray).subscribe((res: string) => {
      this.translatedObj = res;
    });
    this.process = this.navParams.data.process;
    if(this.process == 'question') {
      this.questionId = this.navParams.data.params;
    } else {
      this.answerId = this.navParams.data.params;
    }
  }

  rate(index: number) {
    this.isDisabled = false;
    this.rating = index;
    this.ratingChange.emit(this.rating);
 }

  getColor(index: number) {
    if(index) {
      if(this.isAboveRating(index)) {
        return "star-outline";
      } else {
        return "star";
      }
    } else {
      return "star-outline";
    }
      
  }

  isAboveRating(index: number): boolean {
    return index > this.rating;
  }

  close() {
    const onClosedData: string = "Success";
    this.modalController.dismiss(onClosedData); 
  }

  async closeRatingModal() {
    if (this.apiCallInProgress) {
      return;
    }
    this.apiCallInProgress=true
   var obj;
   if(this.questionId) {
    obj = {
      "QuestionId": this.questionId,
      "Rating": this.rating,
      "Remarks": this.remarks
    }
   } 

   if(this.answerId) {
    obj = {
      "AnswerId": this.answerId,
      "Rating": this.rating,
      "Remarks": this.remarks
    }
   }
   this.global.presentLoading();
   this._generalService.saveReview(obj).then(
    (data) => {
      this.global.dismiss();
      this.apiCallInProgress=false
      const onClosedData: string = "Success";
      this.modalController.dismiss(onClosedData); 
    }
   ).catch(
     (err) => {
       this.global.dismiss();
       this.apiCallInProgress=false
      const onClosedData: string = "Error";
      this.modalController.dismiss(onClosedData); 
     }
    )
          
  }
}
