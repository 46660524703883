import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
  name: 'changeAlphabet'
})
export class NumberToAlphabetPipe implements PipeTransform {
 
  transform(value: number, args?: any): string {
    return  String.fromCharCode(value+97).toUpperCase();
  }
 
}