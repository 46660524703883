// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-body ion-radio {
  --ion-color-base: black !important;
}

.test-character-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.test-character-container .test-character-image {
  width: 50%;
}

ion-item {
  --background: white;
}

.result-fail-label {
  color: red;
}

.result-pass-label {
  color: green;
}

p {
  margin: 0;
}

.question-modal-content {
  padding-left: 5px;
  padding-right: 4rem;
}

.show-sm {
  display: none;
}

.wrapper {
  display: grid;
  align-items: center;
}

@media (max-width: 767px) {
  .wrapper {
    display: block;
  }
  .show-sm {
    display: block;
  }
  .hide-sm {
    display: none;
  }
  .question-modal-content {
    padding-inline: 8px !important;
  }
  .dialog-button {
    padding-inline: 6px !important;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
