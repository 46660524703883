/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import {
  HttpClientModule,
  HttpHeaders,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { GeneralService } from 'src/app/services/general.service';
import { TranslationService } from 'src/app/services/translation.service';

@Injectable({
  providedIn: 'root',
})
export class DomainGuardGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private router: Router,
    public global: GlobalService,
    private httpClient: HttpClient,
    private _generalService: GeneralService,
    public translationService: TranslationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .get('config/config.json', httpOptions)
      .toPromise()
      .then(async ( res: any) => {
        console.log(res.defaultDomainName);
        if (!res.defaultDomainName) {
          return true;
        }

        // localStorage.setItem('defaultDomainName', 'stsdemo');
        // localStorage.setItem('domain', 'stsdemo');

        localStorage.setItem('defaultDomainName', res.defaultDomainName);
        localStorage.setItem('domain', res.defaultDomainName);

        const y = localStorage.getItem('domain');

        const data= await this._generalService.getDomainSetting(y);
        try {
          if (data.DomainLogo) {
            localStorage.ImageData =
              'data:image/png;base64,' + data.DomainLogo;
            this.global.DomainLogo = localStorage.ImageData;
          }
          if (data.Color) {
            document.documentElement.style.setProperty(
              '--ion-color-secondary',
              data.Color
            );
            document.documentElement.style.setProperty(
              '--ion-color-primary',
              data.Color
            );
            localStorage.domainColor = data.Color;
            this.global.domainColor = localStorage.domainColor;
          } else {
            document.documentElement.style.setProperty(
              '--ion-color-secondary',
              '#bdd435'
            );
            document.documentElement.style.setProperty(
              '--ion-color-primary',
              '#08378a'
            );
            localStorage.domainColor = '';
            this.global.domainColor = localStorage.domainColor;
          }

          if (data.DefaultColor) {
            document.documentElement.style.setProperty(
              '--ion-color-default',
              data.DefaultColor
            );
            localStorage.defaultColor = data.DefaultColor;
          } else {
            document.documentElement.style.setProperty(
              '--ion-color-default',
              '#e1dfdd'
            );
            localStorage.defaultColor = '';
          }

          if (data.Locales) {
            localStorage.locales = JSON.stringify(data.Locales);
          } else {
            console.error('No locales received from api');
          }
          console.log('Navigating to login domain page: canActivate(http get config/config.json)');
          this.router.navigate(['login']);
          return false;
        } catch (error) {
          console.log('error: Error while getting domain setting');
          console.log(error);
          this.global.presentAlert(this.global.translate('label-errorMessage'), error.error.message);
        }

      })
      .catch((error) => {
        console.log(error);
        return true;
      });
  }
}
