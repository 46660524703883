import { FilterPipe } from './filter.pipe';
import { OrderByPipe } from './orderBy.pipe';
import { SafePipe } from './safe.pipe';
import { NgModule } from '@angular/core';
import { NumberToAlphabetPipe } from './numberToAlphabet.pipe';
import { CustomDatePipe } from './customDate.pipe';

@NgModule({
	declarations: [
		FilterPipe,
		SafePipe,
		OrderByPipe,
		NumberToAlphabetPipe,
		CustomDatePipe
	],
	imports: [

	],
	exports: [
		FilterPipe,
		SafePipe,
		OrderByPipe,
		NumberToAlphabetPipe,
		CustomDatePipe
	]
})

export class Pipes { }