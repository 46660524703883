import { Pipes } from 'src/app/shared/pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule} from "@ngx-translate/core";
import { HelptextModalPage } from './helptext-modal.page';
import { NgxSummernoteModule } from 'ngx-summernote';

const routes: Routes = [
  {
    path: '',
    component: HelptextModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    Pipes,
    TranslateModule,
    NgxSummernoteModule,
    RouterModule.forChild(routes)
  ],
  declarations: [HelptextModalPage],
})
export class HelptextModalPageModule {}
