import { Component, ViewChild, ElementRef, Output, Input, EventEmitter, Renderer2, OnInit } from '@angular/core';
import { Participant, RemoteTrack, RemoteAudioTrack, RemoteVideoTrack, RemoteParticipant, RemoteTrackPublication } from 'twilio-video';
import { VideochatService } from 'src/app/services/videochat.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { Location } from '@angular/common';


@Component({
    selector: 'app-participants',
    templateUrl: './participants.component.html',
    styleUrls: ['./participants.component.scss'],
})
export class ParticipantsComponent implements OnInit {
    @ViewChild('list') listRef: ElementRef;

    @Output('participantsChanged') participantsChanged = new EventEmitter<boolean>();
    @Output('leaveRoom') leaveRoom = new EventEmitter<boolean>();
    @Input('activeRoomName') activeRoomName: string;

    isCalling: boolean = false;
    showHangupBtn: boolean = false;
    appCallingParticipant: boolean = false;
    userName: string;

    get participantCount() {
        return !!this.participants ? this.participants.size : 0;
    }

    get isAlone() {
        return this.participantCount === 0;
    }

    private participants: Map<Participant.SID, RemoteParticipant>;
    private dominantSpeaker: RemoteParticipant;

    constructor(private readonly renderer: Renderer2,
        private readonly videoChatService: VideochatService,
        private router: Router,
        public global: GlobalService,
        public location: Location) { }


    ngOnInit() {
        this.isCalling = false;
    }

    clear() {
        if (this.participants) {
            this.participants.clear();
        }
    }

    initialize(participants: Map<Participant.SID, RemoteParticipant>) {
        this.appCallingParticipant = true;
        this.participants = participants;
        if (this.participants) {
            this.participants.forEach(participant => this.registerParticipantEvents(participant));
        }
    }

    add(participant: RemoteParticipant) {
        if (this.participants && participant) {
            this.appCallingParticipant = false;
            this.participants.set(participant.sid, participant);
            this.registerParticipantEvents(participant);
        }
    }

    remove(participant: RemoteParticipant) {
        if (this.participants && this.participants.has(participant.sid)) {
            this.participants.delete(participant.sid);
        }
    }

    loudest(participant: RemoteParticipant) {
        this.dominantSpeaker = participant;
    }

    private registerParticipantEvents(participant: RemoteParticipant) {
        participant.tracks.forEach(publication => this.subscribe(publication));
        participant.on('trackPublished', publication => this.subscribe(publication));
        participant.on('trackUnpublished',
            publication => {
                if (publication && publication.track) {
                    this.detachRemoteTrack(publication.track);
                }
            });
    }

    private subscribe(publication: RemoteTrackPublication | any) {
        if (publication && publication.on) {
            publication.on('subscribed', track => this.attachRemoteTrack(track));
            publication.on('unsubscribed', track => this.detachRemoteTrack(track));
        }
    }

    private attachRemoteTrack(track: RemoteTrack) {
        if (this.isAttachable(track)) {
            const element = track.attach();
            // if(element.nodeName =="AUDIO") {
            //     this.renderer.data.id = track.sid;
            //     this.renderer.setStyle(element, 'width', '100%');
            //     this.renderer.setStyle(element, 'height', '100%');
            //     this.renderer.appendChild(this.listRef.nativeElement, element);
            //     this.participantsChanged.emit(true);
            // } else {
            element.setAttribute("style", "width: 100%; height: 100%");
            this.listRef.nativeElement.appendChild(element);
            this.participantsChanged.emit(true);
            //}

        }
    }

    private detachRemoteTrack(track: RemoteTrack) {
        if (this.isDetachable(track)) {
            track.detach().forEach(el => el.remove());
            this.participantsChanged.emit(true);
        }
    }

    private isAttachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
        return !!track &&
            ((track as RemoteAudioTrack).attach !== undefined ||
                (track as RemoteVideoTrack).attach !== undefined);
    }

    private isDetachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
        return !!track &&
            ((track as RemoteAudioTrack).detach !== undefined ||
                (track as RemoteVideoTrack).detach !== undefined);
    }

}
