import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-helptext-modal',
  templateUrl: './helptext-modal.page.html',
  styleUrls: ['./helptext-modal.page.scss'],
})
export class HelptextModalPage implements OnInit {

  helpData: string = '';
  lang:any;
  constructor(private _generalService: GeneralService,
    public global: GlobalService,
    private modalController:ModalController) { 
    }

  ngOnInit() {
  }

  ionViewWillEnter() {
    if (localStorage.locale) {
      this.lang = localStorage.locale;
    } else {
      this.lang = "1033";
    }
    this.getHelpData();
  }

  getHelpData(){
    this.global.presentLoading();   
    this._generalService.getHelpText()
    .then(
      (res) => {
        this.helpData=res.Description.find(x=>x.Locale == this.lang);
        this.global.dismiss();
      }
    )
    .catch(
      (err)=> {
          this.global.dismiss();
console.log('err', err);
      }
    )
  }

  hideModal(){
    const onClosedData: string = "Success";
    this.modalController.dismiss(onClosedData);  
  }
}
