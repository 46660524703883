import { NgModule } from '@angular/core';
import { NumberDirective } from './custom.directive';

@NgModule({
    imports: [
    ],
    declarations: [
        NumberDirective
    ],
    exports: [
        NumberDirective
    ]
})

export class SharedModule {}