import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ComponentFixtureAutoDetect } from '@angular/core/testing';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild('myCanvas', { static: false }) myCanvas: ElementRef;

  stream :any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
  ) {
    
   }

  ngOnInit() {
    this.displayCamera();
  }

  displayCamera(){
      setTimeout(async() => {
      let video = document.getElementById('video');
      let constraints = {
        audio: false,
        video: {
          width: 600,
          height: 500
        }
      }
      try {
        this.stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.handleSuccess(this.stream, video);
        // this.cameraButton = true;
        
      } catch (e) {
        console.log(e);
        this.modalController.dismiss();
        // this.cameraOn = false;
      }
    }, 100);
  }


  async captureWebCamPic(){
    this.myCanvas.nativeElement.width = 500;
    this.myCanvas.nativeElement.height = 375;

    this.myCanvas.nativeElement.getContext('2d').drawImage(document.querySelector('video'), 0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height); // for drawing the video element on the canvas
    this.stream.getTracks()[0].stop();
    this.modalController.dismiss(this.myCanvas.nativeElement.toDataURL("image/jpg"));
  }

  handleSuccess(stream, video) {
    window.MediaStream = stream;
    video.srcObject = stream;

  }


  close() {
    this.stream.getTracks()[0].stop();
    this.modalController.dismiss();
    }
}
