import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { QuestionModalPage } from './question-modal.page';
import { TranslateModule} from "@ngx-translate/core";
import { Pipes } from 'src/app/shared/pipes/pipes.module';

const routes: Routes = [
  {
    path: '',
    component: QuestionModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    Pipes,
    TranslateModule.forChild(),
    RouterModule.forChild(routes)
  ],
  declarations: [QuestionModalPage]
})
export class QuestionModalPageModule {}
