import { Component, ElementRef, ViewChild, AfterViewInit, Renderer2, OnInit } from '@angular/core';
import { createLocalTracks, LocalTrack, LocalVideoTrack, LocalAudioTrack } from 'twilio-video'
import { StorageService } from 'src/app/services/storage.service';
import { GlobalService } from 'src/app/services/global.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class CameraComponent implements OnInit {
  @ViewChild('preview') previewElement: ElementRef;

  get tracks(): LocalTrack[] {
    return this.localTracks;
  }

  isInitializing: boolean = false;

  private audioTrack: LocalAudioTrack;

  videoTrack: LocalVideoTrack = null;
  private localTracks: LocalTrack[] = [];
 
  constructor(
    private readonly storageService: StorageService,public global: GlobalService,public alertController: AlertController,
    private readonly renderer: Renderer2) { }

  ngOnInit() {
   }

  async ngAfterViewInit() {
    if (this.previewElement && this.previewElement.nativeElement) {
      const selectedVideoInput = this.storageService.get('videoInputId');
      await this.initializeDevice('videoinput', selectedVideoInput, false);
    }
  }

  async initializePreview(deviceInfo?: MediaDeviceInfo) {
    if (deviceInfo) {
      await this.initializeDevice(deviceInfo.kind, deviceInfo.deviceId, true);
    } else {
      this.initializeDevice();
    }
  }

  async finalizePreview() {
    try {
      if (this.videoTrack) {
        this.videoTrack.mediaStreamTrack.stop();
        this.videoTrack.mediaStreamTrack.enabled = false;
        this.videoTrack.detach().forEach(element => element.remove());
      }
      if (this.audioTrack) {
        this.audioTrack.mediaStreamTrack.stop();
        this.audioTrack.mediaStreamTrack.enabled = false;
        this.audioTrack.detach().forEach(element => element.remove());
      }
    } catch (e) {
      console.error(e);
    }
  }

  private async initializeDevice(kind?: MediaDeviceKind, deviceId?: string, status?: boolean) {
    if (status == true) {
      try {
        this.isInitializing = true;

        this.finalizePreview();

        this.localTracks = kind && deviceId
          ? await this.initializeTracks(kind, deviceId)
          : await this.initializeTracks();

        this.videoTrack = this.localTracks.find(t => t.kind === 'video') as LocalVideoTrack;
        this.audioTrack = this.localTracks.find(t => t.kind === 'audio') as LocalAudioTrack;
        const videoElement = this.videoTrack.attach();
        this.renderer.setStyle(videoElement, 'height', '100%');
        //this.renderer.setStyle(videoElement, 'width', '100%');
        this.renderer.appendChild(this.previewElement.nativeElement, videoElement);
      }catch(error){
       
        if(error.message=="Could not start video source"){
          this.global.dismiss();
          this.presentAlertConfirm();
        }
        
      } finally {
        this.isInitializing = false;
      
      }
    }
  }

  private initializeTracks(kind?: MediaDeviceKind, deviceId?: string) {
    if (kind) {
      switch (kind) {
        case 'audioinput':
          return createLocalTracks({ audio: { deviceId }, video: true });
        case 'videoinput':
          return createLocalTracks({ audio: true, video: { deviceId } });
      }
    }

    return createLocalTracks({ audio: true, video: true });
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: this.global.translate('label-videoAssistance'),
      message: this.global.translate('label-videoBackGroundRun'),
      buttons: [
         {
          text: this.global.translate('label-ok'),
          handler: () => {
        }
      }]
  });

    await alert.present();
  }

  stopCamera() {
    this.videoTrack.mediaStreamTrack.stop();
  }

  toggleAudio() {

  }
}
