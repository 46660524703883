/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable eqeqeq */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service';
import { GeneralService } from 'src/app/services/general.service';
import { RatingModalPage } from '../rating-modal/rating-modal.page';
import { Router } from '@angular/router';
import { SharedService} from '../../../services/questionmodal.service';
import { AlertController } from '@ionic/angular';
import { HomePage } from 'src/app/home/home.page';
import { HttpClient } from '@angular/common/http';



@Component({
  selector: 'app-question-modal',
  templateUrl: './question-modal.page.html',
  styleUrls: ['./question-modal.page.scss'],
})
export class QuestionModalPage implements OnInit {
  langArray = ['label-noTimeToAnswer'];
  questionDatas: any = {};
  question = '';
  answers: any = [];
  questionImage = '';
  isChecked = false;
  isDisabled = false;
  translatedObj: any;
  enableRating = false;
  type = '';
  testResult = false;
  completeTest = false;
  score = '';
  questionCount: Number = 1;
  allQuestionAnswered =  false;
  questionsStarted: Boolean = false;
  userFirstName!: String;
  translations!: String;
  translationResult !: String;
  KnowledgeTestPassLimitPercentage!: Number;
  correctAnswerPercent: any ='';
  skipAnswerValue = 'false';

  loadingQuestion = false;
  knowledgeTestIdFromHome: string;
  knowledgeTestId: string;
  testtaken='0';
  previousknowledgetestid: string;
  // isStsdemo: boolean;

  constructor(
    private _generalService: GeneralService,
    public global: GlobalService,
    private _translate: TranslateService,
    private modalController: ModalController,
    private router: Router,
    private navParams: NavParams,
    private sharedService: SharedService,
    private alertController: AlertController,
    private httpClient: HttpClient,
    private changeDetectorRef: ChangeDetectorRef) {
      this._translate.onLangChange.subscribe((event) => {
        this._translate.get(this.langArray).subscribe((res: string) => {
          this.translatedObj = res;
        });
      });
    }

  ngOnInit() {
    // this.httpClient
    // .get('config/config.json')
    // .toPromise()
    // .then(async (res: any) => {
    //   console.log(res.defaultDomainName);
    //   if (!res.defaultDomainName) {
    //     return true;
    //   }
    //   const defaultDomainName = res.defaultDomainName;
    //   if (defaultDomainName.toLowerCase() === 'stsdemo'){
    //      this.isStsdemo = true;
    //   }
    //   else{
    //     this.isStsdemo = false;
    //   }
    // })
    // .catch((error: any) => {
    //   console.error(error);
    // });

    this.knowledgeTestIdFromHome = this.sharedService.getKnowledgeTestId();
    console.log(this.knowledgeTestIdFromHome);
    this.previousknowledgetestid = localStorage.getItem('knowledgetestid');
    localStorage.setItem('knowledgetestid',this.knowledgeTestIdFromHome);
    if(this.knowledgeTestIdFromHome===this.previousknowledgetestid)
    {
      this.testtaken = '1';
      localStorage.setItem('TestTakenCount',this.testtaken);
    }
    else
    {
      this.testtaken = '0';
      localStorage.setItem('TestTakenCount',this.testtaken);
    }
    this._translate.get(this.langArray).subscribe((res: string) => {
      this.translatedObj = res;
    });
    if (localStorage.appSettings && localStorage.appSettings != '') {
      const appSetting = JSON.parse(localStorage.appSettings);
    const skipAnswerCheck = appSetting.filter(x => x.SettingName == 'DontHaveTimeToAnswer')[0];
    this.skipAnswerValue = skipAnswerCheck ? skipAnswerCheck.SettingValue.toLowerCase() : 'true';
    // console.log(skipAnswerCheck, this.skipAnswerValue);
    }
    localStorage.removeItem('pushData');
    localStorage.removeItem('externalLink');
    localStorage.removeItem('externalURL');
    this.questionDatas = this.navParams.data.params;
    this.type = this.questionDatas.type ? this.questionDatas.type : '';
    this.userFirstName = this.questionDatas.FirstName;
    this.translations = this.questionDatas.Translation;
    this.KnowledgeTestPassLimitPercentage = this.questionDatas.KnowledgeTestPassLimitPercentage;
    if(this.questionDatas.ImageData) {
      this.questionDatas.Image = 'data:image/png;base64,'+this.questionDatas.ImageData;
    }
    this.question = this.questionDatas.Question;
    this.questionImage = this.questionDatas.Image;
    this.answers = this.questionDatas.Answers ? this.questionDatas.Answers : [];
    if(this.type == 'question') {
      this.questionsStarted = true;
      if(this.skipAnswerValue == 'true'){
      const skipAnswer = {
        QuestionAnswerId: null,
        TranslationText: this.translatedObj['label-noTimeToAnswer']
      };
      this.answers.push(skipAnswer);
    }
    }
    for(let i=0; i< this.answers.length;i++) {
      this.answers[i].selectedAnswer = false;
    }
    this.changeDetectorRef.detectChanges();
    if(localStorage.enableRating) {
      if(localStorage.enableRating == 'true') {
        this.enableRating = true;
      } else {
        this.enableRating = false;
      }
    }
  }

  openExitPopup(){
    // console.log(this.type);
    if(this.type==='question'){
      this.presentConfirmationAlertForQuestion();
    }else{
      this.presentConfirmationAlertForTakeATest();

    }
  }

  async closeModal() {
    this.selectedQuestionAnswerID='';
      const onClosedData = 'Success';
      this.modalController.dismiss(onClosedData);
      this.completeTest = false;
      this.global.triggerGetTileItems();
      this.global.triggerGetAnswersList();
      this.modalController.dismiss();

  }

  async presentConfirmationAlertForQuestion() {
    const alert = await this.alertController.create({
      header: this.global.translate('label-pleaseConfirm'),
      message: this.global.translate('label-exitPromptForQuestion'),
      buttons: [
        {
          text: this.global.translate('label-no'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          text: this.global.translate('label-yes'),
          handler: () => {
            this.closeModal();
          }
        }
      ]
    });
    await alert.present();
  }

  async presentConfirmationAlertForTakeATest() {
    const alert = await this.alertController.create({
      header: this.global.translate('label-pleaseConfirm'),
      message: this.global.translate('label-exitPromptForTakeATest'),
      buttons: [
        {
          text: this.global.translate('label-no'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        },
        {
          text: this.global.translate('label-yes'),
          handler: () => {
            this.closeModal();
          }
        }
      ]
    });
    await alert.present();
  }
    

private selectedQuestionAnswerID:any='';
  checkAnswer(item) {

    // console.log(event)
    event.stopImmediatePropagation()
    // console.log(item)
    this.isDisabled = true;
    for(let i=0; i< this.answers.length;i++) {
      if(this.answers[i].QuestionAnswerId != item.QuestionAnswerId) {
        this.answers[i].selectedAnswer = false;
      } else {
        this.answers[i].selectedAnswer = true;
      }
    }
    if(this.questionCount == this.global.questionnaireCount) {
      this.allQuestionAnswered = true;
    } else {
      this.allQuestionAnswered = false;
    }

    if(this.type == 'question') {
      if (this.selectedQuestionAnswerID!==''){
        return;
      }this.selectedQuestionAnswerID=item.QuestionAnswerId;
      this.global.presentLoading();
      const obj = {
        UserQuestionId : this.questionDatas.UserQuestionId,
        QuestionId : this.questionDatas.QuestionId,
        QuestionAnswerId : item.QuestionAnswerId,
        AnswerStatus : item.QuestionAnswerId ? 'Answered' : 'Rejected'
      };

      this._generalService.updateUserQuestions(this.questionDatas.UserQuestionId, obj).then(
        (data)=> {
          if(item.QuestionAnswerId){
            this.isChecked = true;
 	          this.correctAnswerPercent = data.CorrectAnswerPercent;
          }
          if(data && data.CorrectQuestionAnswerId) {
            const correctAnswer = this.answers.find(x => x.QuestionAnswerId == data.CorrectQuestionAnswerId);
            if(correctAnswer) {
              correctAnswer.IsCorrectAnswer = true;
              this.questionDatas.Explanation = data.Explanation ? data.Explanation : '';
            }
          }
          this.global.dismiss();
        },
        (err)=> {
          this.global.dismiss();
        }
      );
    } else if(this.type == 'takeatest') {
      this.global.presentLoading();
      const objParam = {
        KnowledgeTestQuestionId: this.questionDatas.KnowledgeTestQuestionId,
        AnswerId: item.QuestionAnswerId,
      };

      this._generalService.updateKnowledgeTestQuestion(this.questionDatas.KnowledgeTestId, objParam).then(
        (data) => {
          if(item.QuestionAnswerId){
            this.isChecked = true;
          }
          if(data && data.CorrectQuestionAnswerId) {
            const correctAnswer = this.answers.find(x => x.QuestionAnswerId == data.CorrectQuestionAnswerId);
            if(correctAnswer) {
              correctAnswer.IsCorrectAnswer = true;
              this.questionDatas.Explanation = data.Explanation ? data.Explanation : '';
            }
          }
          this.global.dismiss();
        },
        (err) => {
          this.global.dismiss();
        }
      );
    }

  }

  askQuestion() {
    this.selectedQuestionAnswerID=''
    this.global.presentLoading();
    this.loadingQuestion = true;


    this._generalService.sendPushNotification().then(
      (res) => {
        const userQuestionId = res.UserQuestionId;
        this._generalService.getUserQuestions(userQuestionId, localStorage.locale).then(
          (obj) => {
            this.global.dismiss();
            this.loadingQuestion = false;
            this.isDisabled = false;
            this.isChecked = false;
            if(this.questionCount != this.global.questionnaireCount) {
              this.questionCount = +this.questionCount + 1;
            } else {
              this.questionCount = 1;
            }
            obj.type = 'question';
            this.questionDatas = obj;
            this.type = this.questionDatas.type ? this.questionDatas.type : '';
            if(this.questionDatas.ImageData) {
              this.questionDatas.Image = 'data:image/png;base64,'+this.questionDatas.ImageData;
            }

            this.question = this.questionDatas.Question;
            this.questionImage = this.questionDatas.Image;
            this.answers = this.questionDatas.Answers ? this.questionDatas.Answers : [];


            if(this.skipAnswerValue == 'true'){
              const skipAnswer = {
                QuestionAnswerId: null,
                TranslationText: this.translatedObj['label-noTimeToAnswer']
              };
              this.answers.push(skipAnswer);
            }

            for(let i=0; i< this.answers.length;i++) {
              this.answers[i].selectedAnswer = false;
            }
            this.changeDetectorRef.detectChanges();
          }
        ).catch(err => {
          console.log(err);
          this.loadingQuestion = false;
          this.global.dismiss();
        });
      },
      (err) => {
        console.log('err: ' + err);
        this.loadingQuestion = false;
      }
    );
  }

  async openRatingModal() {
      const modal = await this.modalController.create({
      component: RatingModalPage,
      backdropDismiss: false,

      cssClass: 'question-modal',
      componentProps: {
        process: 'question',
        params: this.questionDatas.QuestionId,
      }

    });

    modal.onDidDismiss().then((dataReturned) => {

    });

    return await modal.present();
  }


  nextQuestion() {
    this.global.presentLoading();
    this.loadingQuestion = true;
    this._generalService.getKnowledgeTestQuestion(this.questionDatas.KnowledgeTestId, localStorage.locale).then(
      (obj) => {
        this.questionDatas = obj;
        this.global.dismiss();
        this.loadingQuestion = false;
        if(this.questionDatas.ImageData) {
          this.questionDatas.Image = 'data:image/png;base64,'+this.questionDatas.ImageData;
        }
        this.question = this.questionDatas.Question;
        this.questionImage = this.questionDatas.Image;
        this.answers = this.questionDatas.Answers ? this.questionDatas.Answers : [];

        for(let i=0; i< this.answers.length;i++) {
          this.answers[i].selectedAnswer = false;
        }
        this.isDisabled = false;
        this.isChecked = false;
        this.changeDetectorRef.detectChanges();
      }
    ).catch(err => {
      console.log(err);
      this.loadingQuestion = false;
      this.global.dismiss();
    });

  }

  getUserTestResult() {
    this.testtaken='1';
    localStorage.setItem('TestTakenCount',this.testtaken);
    this.global.presentLoading();
    this._generalService.getUserTestResult(this.questionDatas.KnowledgeTestId).then(
      (obj) => {
        try {
          this.score = obj.KnowledgeTestPercentage;
          this.translationResult = obj.Translation;
          if(obj.KnowledgeTestStatus.toLowerCase() == 'passed') {
            this.testResult = true;
          } else {
            this.testResult = false;
          }
          this.completeTest = true;
          this.global.dismiss();
        } catch (error) {
          this.closeModal();
          this.global.presentAlert(this.global.translate('label-errorMessage'), this.global.translate('label-errorMessage'));
        }
      }
    ).catch(err => {
      console.log(err);
      this.global.dismiss();
    });
  }

  showResultModal(){
    if(this.type =='takeatest'){
      if(this.questionDatas.IsLastQuestion) {
        this.getUserTestResult();
      }
    }
  }

  navigate(option) {
    this.closeModal();
    if(option == 'answer') {
      this.router.navigate(['/answer', this.questionDatas.ItemId, this.questionDatas.AnswerId]);
    } else {
      window.open(this.questionDatas.MoreInfoURL,'_blank');
    }
  }

  async takeatests(){
    this.questionsStarted = true;
    this.global.presentLoading();
    this.questionDatas= '';
    this.testtaken=localStorage.getItem('TestTakenCount');
    // console.log(this.knowledgeTestIdFromHome);

    if(this.knowledgeTestIdFromHome!==undefined && (this.testtaken==='0'|| this.testtaken===null))
{
   this.knowledgeTestId = this.knowledgeTestIdFromHome;
   await this._generalService.increaseKnowledgeTestCount(this.knowledgeTestId, true).then(
    (data) => {}).catch(err => {
    });
    await this._generalService.getKnowledgeTestQuestion(this.knowledgeTestId, localStorage.locale).then(
       (obj) => {

         this.global.dismiss();
         obj.type = 'takeatest';
         this.questionDatas = obj;
         this.KnowledgeTestPassLimitPercentage = this.questionDatas.KnowledgeTestPassLimitPercentage;
 if(this.questionDatas.ImageData) {
   this.questionDatas.Image = 'data:image/png;base64,'+this.questionDatas.ImageData;
 }
 this.question = this.questionDatas.Question;
 this.questionImage = this.questionDatas.Image;
 this.answers = this.questionDatas.Answers ? this.questionDatas.Answers : [];
 if(this.type == 'question') {
   this.questionsStarted = true;
   if(this.skipAnswerValue == 'true'){
   const skipAnswer = {
     QuestionAnswerId: null,
     TranslationText: this.translatedObj['label-noTimeToAnswer']
   };
   this.answers.push(skipAnswer);
 }
 }
 for(let i=0; i< this.answers.length;i++) {
   this.answers[i].selectedAnswer = false;
 }
 this.changeDetectorRef.detectChanges();
 if(localStorage.enableRating) {
   if(localStorage.enableRating == 'true') {
     this.enableRating = true;
   } else {
     this.enableRating = false;
   }
 }
}).catch(err => {
       console.log(err);
       this.global.dismiss();
     });}

 else{
  this._generalService.takeTest({}).then(
    async (res) => {
       this.knowledgeTestId = res.KnowledgeTestId;
       await this._generalService.increaseKnowledgeTestCount(this.knowledgeTestId, true).then(
        (data) => {}).catch(err => {
        });
      await this._generalService.getKnowledgeTestQuestion(this.knowledgeTestId, localStorage.locale).then(
         (obj) => {
           this.global.dismiss();
           obj.type = 'takeatest';
           this.questionDatas = obj;
           this.KnowledgeTestPassLimitPercentage = this.questionDatas.KnowledgeTestPassLimitPercentage;
   if(this.questionDatas.ImageData) {
     this.questionDatas.Image = 'data:image/png;base64,'+this.questionDatas.ImageData;
   }
   this.question = this.questionDatas.Question;
   this.questionImage = this.questionDatas.Image;
   this.answers = this.questionDatas.Answers ? this.questionDatas.Answers : [];
   if(this.type == 'question') {
     this.questionsStarted = true;
     if(this.skipAnswerValue == 'true'){
     const skipAnswer = {
       QuestionAnswerId: null,
       TranslationText: this.translatedObj['label-noTimeToAnswer']
     };
     this.answers.push(skipAnswer);
   }
   }
   for(let i=0; i< this.answers.length;i++) {
     this.answers[i].selectedAnswer = false;
   }
   this.changeDetectorRef.detectChanges();
   if(localStorage.enableRating) {
     if(localStorage.enableRating == 'true') {
       this.enableRating = true;
     } else {
       this.enableRating = false;
     }
   }
         }
       ).catch(err => {
         console.log(err);
         this.global.dismiss();
       });
     },
     (err) => {
       this.global.dismiss();
       this.global.presentAlert('', err.error.message ? this.global.translate(err.error.message) : this.global.translate('label-questionNotLinked'));
     }
   ).catch(
     (error) => {
       this.global.dismiss();
       this.global.presentAlert('', error.error.message ? this.global.translate(error.error.message) : this.global.translate('label-questionNotLinked'));
     }
   );
 }
  }
}
