import { Injectable, NgZone } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { GlobalService } from '../services/global.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    alert: any;
    supportuserId:any = "";

    constructor(private router: Router, private _ngZone: NgZone, public global: GlobalService) {
        
        
     }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if(localStorage.getItem('supportuserId') ){
            this.supportuserId = localStorage.getItem('supportuserId');
            const authReq = request.clone({
                //headers: request.headers
                //    .set('Referenced-User', 'XXXXXX')
                
                setHeaders: {
                    //'Cache-Control': 'no-cache',
                    //Pragma: 'no-cache'
                    'Referenced-User':  this.supportuserId ,
                }
            });
            return next.handle(authReq);
        }
        return next.handle(request);
       
        
        
    }

}