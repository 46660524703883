import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private knowledgeTestId: string;

  constructor() {}

  setKnowledgeTestId(id: string) {
    this.knowledgeTestId = id;
  }

  getKnowledgeTestId(): string {
    return this.knowledgeTestId;
  }
}
