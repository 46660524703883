import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { DatePipe } from '@angular/common';
@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  constructor(private global: GlobalService, private datePipe: DatePipe){}
  transform(value:string,args?: any): string {
    if(args == 'date') {
      return `${this.datePipe.transform(value,'dd')} ${this.global.translate(`label-${this.datePipe.transform(value,'MMM').toLowerCase()}`)}`;
    } else {
      if(new Date(value).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)) {
        return this.datePipe.transform(value,'HH:mm');
      } else {
        return `${this.datePipe.transform(value,'dd')} ${this.global.translate(`label-${this.datePipe.transform(value,'MMM').toLowerCase()}`)}`;
      }
    }
  }
}
