/* eslint-disable @typescript-eslint/naming-convention */
import { GeneralService } from './general.service';
import { Platform } from '@ionic/angular';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  scormValue:any;
  testValue:any;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;


  constructor(private platform: Platform, public global: GlobalService, private _httpClient: HttpClient, private _generalService: GeneralService, private _translate: TranslateService) {
    if (localStorage.authenticationKey != null && localStorage.userName != null && localStorage.userId != null && localStorage.authenticationKey.length > 0) {
      this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('authenticationKey'));
    } else {
      this.currentUserSubject = new BehaviorSubject<any>(null);
    }
    this.currentUser = this.currentUserSubject.asObservable();
  }

  get authenticated(): any {
    return this.currentUserSubject.value;
  }

  doLogin(params: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': params.XLoginSource
      })
    };
    return this._httpClient.post(params.url, params.params, httpOptions).pipe(timeout(10000), map((res: any) => {
      if (res && res.AuthenticationKey) {
        localStorage.setItem('authenticationKey', res.AuthenticationKey);
        this.currentUserSubject.next(res);
      }
      return res;
    }),
      catchError(
        (err: Response) => this.handleError(err)
      ));
  }

  emailLinkAndLoginCodeAuthenticationLogin(params): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': params.XLoginSource
      })
    };
    return this._httpClient.post(params.url, params.params, httpOptions).pipe(timeout(10000), map((res: any) => {
      if (res && res.AuthenticationKey) {
        localStorage.setItem('authenticationKey', res.AuthenticationKey);
        this.currentUserSubject.next(res);
      }
      return res;
    }),
      catchError(
        (err: Response) => this.handleError(err)
      ));
  }

  doOAuth() {
    this._generalService.isOauth = true;
  }

  doLogout(params: any): Observable<any> {
    let authenticationKey = null;
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'mobile',
        'X-Security-AuthKey': params.authenticationkey
      })
    };
    if (authenticationKey !== null) {
      httpOptions.headers.append("X-Security-AuthKey", authenticationKey);
    }
    return this._httpClient.put(params.url, params.params, httpOptions).pipe(timeout(10000), map((res: any) => {
      return res;
    }),
      catchError(
        (err: Response) => this.handleError(err)
      )
    );
  }

  passwordChangeRequest(params: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': params.XLoginSource
      })
    };
    return this._httpClient.post(params.url, params.params, httpOptions).pipe(timeout(10000), map((res: any) => {
      if (res) {
      }
      return res;
    }),
      catchError(
        (err: Response) => this.handleError(err)
      ));
  }

  checkDomain(name: string): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'mobile',
        'X-Security-AuthKey': ""
      })
    };
    return this._httpClient.get(this.global.ssoApiURL + "/URL/" + name, httpOptions).pipe(map((res: any) => {
      return res;
    }),
      catchError(
        (err: Response) => this.handleError(err)
      )
    );
  }

  ssoLogin(params: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': params.XLoginSource
      })
    };
    return this._httpClient.post(params.url, params.params, httpOptions).pipe(timeout(10000), map((res: any) => {
      if (res) {
        localStorage.setItem('authenticationKey', res.AuthenticationKey);
        this.currentUserSubject.next(res);
      }
      return res;
    }),
      catchError(
        (err: Response) => this.handleError(err)
      ));
  }

  verifyPasswordChangeRequest(id): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'mobile',
        'X-Security-AuthKey': ""
      })
    };
    return this._httpClient.get(this.global.apiURL + "/PasswordChangeRequest/" + id, httpOptions).pipe(map((res: any) => {
      return res;
    }),
      catchError(
        (err: Response) => this.handleError(err)
      )
    );
  }

  changePassword(data, id): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'mobile',
        'X-Security-AuthKey': ""
      })
    };
    return this._httpClient.post(this.global.apiURL + "/PasswordChangeRequest/" + id + "/Reset", data, httpOptions).pipe(timeout(10000), map((res: any) => {
      return res;
    }),
      catchError(
        (err: Response) => this.handleError(err)
      ));
  }

  verifyInvitionCode(id: string): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'mobile',
        'X-Security-AuthKey': ""
      })
    };
    return this._httpClient.get(this.global.apiURL + "/Users/activate/" + id, httpOptions).pipe(map((res: any) => {
      return res;
    }),
      catchError(
        (err: Response) => this.handleError(err)
      )
    );
  }

  activateUser(id, data): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'mobile'
      })
    };
    return this._httpClient.patch(this.global.apiURL + "/Users/" + id, data, httpOptions).pipe(timeout(10000), map((res: any) => {
      return res;
    }),
      catchError(
        (err: Response) => this.handleError(err)
      ));
  }

  doVerifyPassCode(params: any): Observable<any> {
    let httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'X-LoginSource': 'mobile',
        'X-Security-AuthKey': localStorage.otpAuthenticationKey
      })
    };
    return this._httpClient.post(this.global.apiURL + "/Login/VerifyPassCode", params, httpOptions).pipe(timeout(10000), map((res: any) => {
      if (res) {
        localStorage.setItem('authenticationKey', res.AuthenticationKey);
        this.currentUserSubject.next(res);
      }
      return res;
    }),
      catchError(
        (err: Response) => this.handleError(err)
      ));
  }

  private handleError(error: Response) {
    console.log(error);
    this.global.dismiss();
    if (error.status == 0 && error["name"] == "TimeoutError") {
      this.global.presentAlert(this.global.translate('label-errorMessage'), this.global.translate('label-sessionTimeout'));
      ////this.global.log("ERROR", "Session timeout", error["message"]);
    } else if (error.status == 0) {
      this.global.presentAlert(this.global.translate('label-errorMessage'), this.global.translate('label-noInternet'));
    }
    return throwError(error);
  }
  async setAuth() {
    if (localStorage.authenticationKey != null && localStorage.userName != null && localStorage.userId != null && localStorage.authenticationKey.length > 0) {

      let data = await this._generalService.getSettings();
      localStorage.appSettings = JSON.stringify(data);

      for (var i = 0; i < data.length; i++) {
        if (data[i].SettingName.toLowerCase() == "customercolor") {
          if (data[i].SettingValue) {
            document.documentElement.style.setProperty('--ion-color-secondary', data[i].SettingValue);
            document.documentElement.style.setProperty('--ion-color-primary', data[i].SettingValue);
            localStorage.domainColor = data[i].SettingValue;
            this.global.domainColor = localStorage.domainColor;
          } else {
            document.documentElement.style.setProperty('--ion-color-secondary', '#bdd435');
            document.documentElement.style.setProperty('--ion-color-primary', '#08378a');
            localStorage.domainColor = "";
            this.global.domainColor = localStorage.domainColor;
          }
        }
        if (data[i].SettingName.toLowerCase() == "homescreenscantext") {
          if (data[i].SettingValue == "true") {
            this.global.enableVinScan = true;
          } else {
            this.global.enableVinScan = false;
          }
        }

        if (data[i].SettingName.toLowerCase() == "homescreenscanqrcode") {
          if (data[i].SettingValue == "true") {
            this.global.enableQRCodeScan = true;
          } else {
            this.global.enableQRCodeScan = false;
          }
        }

        if (data[i].SettingName.toLowerCase() == "homescreenrecentlist") {
          if (data[i].SettingValue == "true") {
            this.global.enableRecentList = true;
          } else {
            this.global.enableRecentList = false;
          }
        }

        if (data[i].SettingName.toLowerCase() == "homescreensearchkeywords") {
          if (data[i].SettingValue == "true") {
            this.global.enableSearchKeyword = true;
          } else {
            this.global.enableSearchKeyword = false;
          }
        }

        if (data[i].SettingName.toLowerCase() == "enableuserrating") {
          if (data[i].SettingValue == "true") {
            localStorage.enableRating = "true";
          } else {
            localStorage.enableRating = "false";
          }
        }

        if (data[i].SettingName.toLowerCase() == "enablequestionaire") {
          if (data[i].SettingValue == "true") {
            this.global.enableQuestionaire = true;
          } else {
            this.global.enableQuestionaire = false;
          }
        }

        if (data[i].SettingName.toLowerCase() == "enablechecklists") {
          if (data[i].SettingValue == "true") {
            this.global.enableCheckLists = true;
          } else {
            this.global.enableCheckLists = false;
          }
        }

        if (data[i].SettingName.toLowerCase() == "enablewritetag") {
          if (data[i].SettingValue == "true") {
            this.global.enableWriteTag = true;
          } else {
            this.global.enableWriteTag = false;
          }
        }

        if (data[i].SettingName.toLowerCase() == "enabletaketest") {
          if (data[i].SettingValue == "true") {
            this.global.enableTakeTest = true;
          } else {
            this.global.enableTakeTest = false;
          }
        }

        if (data[i].SettingName == 'HideTakeATestPWAMenu') {
          if (data[i].SettingValue == 'true') {
            this.global.HideTakeATestPWAMenu = true;
          } else {
            this.global.HideTakeATestPWAMenu = false;
          }
        }

        if (data[i].SettingName.toLowerCase() == "homescreeninfoblock") {
          if (data[i].SettingValue == "true") {
            this.global.enableInfoBlock = true;
          } else {
            this.global.enableInfoBlock = false;
          }
        }

        if (data[i].SettingName.toLowerCase() == "enablereportincident") {
          if (data[i].SettingValue == "true") {
            this.global.enableReportIncident = true;
          } else {
            this.global.enableReportIncident = false;
          }
        }

        if (data[i].SettingName.toLowerCase() == "enablemessages") {
          if (data[i].SettingValue == "true") {
            this.global.enableMessages = true;
          } else {
            this.global.enableMessages = false;
          }
        }
        // if (data[i].SettingName.toLowerCase() == "questionairecount") {
        //   if (data[i].SettingValue && data[i].SettingValue != '0') {
        //     if(isNaN(Number(data[i].SettingValue))) {
        //       this.global.questionnaireCount = 1;
        //     } else {
        //       this.global.questionnaireCount = Number(data[i].SettingValue);
        //     }
        //   } else {
        //     this.global.questionnaireCount = 1;
        //   }
        // }
        if (data[i].SettingName.toLowerCase() == "pwalinkphonecalltomobile") {
          if (data[i].SettingValue == "true") {
            this.global.phoneCallToLinkMobile = true;
          } else {
            this.global.phoneCallToLinkMobile = false;
          }
        }

        if (data[i].SettingName.toLowerCase() == "hidelogoutbutton") {
          if (data[i].SettingValue == "true") {
            this.global.hideLogoutButton = true;
          } else {
            this.global.hideLogoutButton = false;
          }
        }
        if(data[i].SettingName.toLowerCase() == "enablescorm"){
          this.scormValue = data[i].SettingValue;
        }
        if(data[i].SettingName.toLowerCase() == "enabletaketest"){
          this.testValue = data[i].SettingValue;
        }
          if (this.scormValue == "true" || this.testValue == "true") {
            this.global.enableScorm = true;
          } else {
            this.global.enableScorm = false;
          }
      }
    }
  }

}
