/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { GeneralService } from 'src/app/services/general.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { LoadingController, Platform, AlertController, MenuController } from '@ionic/angular';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { IonSelect } from '@ionic/angular';
import { TranslationService } from 'src/app/services/translation.service';
import { ModalController } from '@ionic/angular';
import { filter, pairwise } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuestionModalPage } from './shared/components/question-modal/question-modal.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  licenseInfo = '';
  loginPages: any;
  homePages: any;
  customLocaleSelect = {
    cssClass: 'customLocaleSelect'
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      'Content-Type': 'application/json'
    })
  };

  @ViewChild(IonSelect) select: IonSelect;
  domainUrl: any;
  id: string;
  scormValue: any;
  testValue: any;
  firstPageObj: any;
  apiCallInProgress=false;
  disablePushNotification: string;
  constructor(
    private route: ActivatedRoute,
    private platform: Platform,
    private _generalService: GeneralService,
    public global: GlobalService,
    private router: Router,
    public loadingController: LoadingController,
    public translationService: TranslationService,
    public modalController: ModalController,
    private alertController: AlertController,
    private _authService: AuthService,
    private menu: MenuController,
    private httpClient: HttpClient
  ) {
    this.menu.enable(false, 'homeMenu');
    this.router.events.subscribe(
      async (event: any) => {
        // if (event instanceof NavigationStart) {
        //   const data: any = await this.httpClient.get('config/config.json', this.httpOptions).toPromise();
        //   this.global.configDomain= data.configDomainName;
        //   const currentApplicationVersion = data.buildRevision;
        //   if (localStorage.appVersion && currentApplicationVersion != localStorage.appVersion) {
        //     localStorage.appVersion = currentApplicationVersion;
        //     this.onVersionChange();
        //   } else {
        //     localStorage.appVersion = currentApplicationVersion;
        //   }
        // }

        if (event instanceof NavigationEnd) {

          const url = event.url;
          if (url.startsWith('/login') || url.startsWith('/login/domain') || url.startsWith('/videoplayer') || url.startsWith('/forgot-password') || url.startsWith('/user/invitation') || url.startsWith('/loginverification') || url.startsWith('/setup2fa') || url.startsWith('/oauth') || url.startsWith('/EmaillinkAuthentication') || url.startsWith('/remoteSupport')) {
            this.menu.enable(false, 'homeMenu');
          } else {
            if (localStorage.authenticationKey && localStorage.authenticationKey != '' && localStorage.authenticationKey != 'undefined') {
              this.menu.enable(true, 'homeMenu');
            }
          }
        }
      }
    );
    this.initializeApp();
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.global.previousURL = events[0].urlAfterRedirects;
        const url = events[1].urlAfterRedirects;
        if (url.startsWith('/login') || url.startsWith('/login/domain') || url.startsWith('/videoplayer') || url.startsWith('/forgot-password') || url.startsWith('/user/invitation') || url.startsWith('/loginverification') || url.startsWith('/setup2fa') || url.startsWith('/oauth') || url.startsWith('/EmaillinkAuthentication') || url.startsWith('/remoteSupport')) {
          this.menu.enable(false, 'homeMenu');
        } else {
          this.menu.enable(true, 'homeMenu');
        }
      });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (localStorage.ImageData) {
        this.global.DomainLogo = localStorage.ImageData;
      } else {
        this.global.DomainLogo = 'assets/icon.png';
      }

      if (localStorage.showContentDomainLogo) {
        if (localStorage.showContentDomainLogo == 'true')
          {this.global.showContentDomainLogo = true;}
        else
          {this.global.showContentDomainLogo = false;}
      } else {
        this.global.showContentDomainLogo = false;
      }

      if (localStorage.hasParentDomain) {
        if (localStorage.hasParentDomain == 'true')
          {this.global.hasParentDomain = true;}
        else
          {this.global.hasParentDomain = false;}
      } else {
        this.global.hasParentDomain = false;
      }
      if (localStorage.parentDomainLogoData) {
        this.global.parentDomainLogo = localStorage.parentDomainLogoData;
      } else {
        this.global.parentDomainLogo = 'assets/icon.png';
      }
      if (localStorage.domain) {
        this.global.DomainName = localStorage.domain;
      }
      if (localStorage.domainColor && localStorage.domainColor != '') {
        document.documentElement.style.setProperty('--ion-color-secondary', localStorage.domainColor);
        document.documentElement.style.setProperty('--ion-color-primary', localStorage.domainColor);
        this.global.domainColor = localStorage.domainColor;
      }

      if (localStorage.defaultColor && localStorage.defaultColor != '') {
        document.documentElement.style.setProperty('--ion-color-default', localStorage.defaultColor);
      }

      setInterval(() => {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('firebase-messaging-sw.js').then(registration => {
            if (registration) {
              if (registration.active) {
                registration.active.postMessage('ping');
              }
              navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
                if (registration) {
                  if (registration.active) {
                    registration.active.postMessage('ping');
                  }
                }
              });
            }
          });
        }
      }, 5000);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 992) {
      if(!this.global.showCustomMenuButton){
        this.global.showSideMenu = true;
      }
      this.global.showCustomMenuButton = true;
    }else{
      this.global.showCustomMenuButton = false;
      this.global.showSideMenu = false;
    }

  }

  logout() {
    localStorage.firstLogIn = false;
    this.presentAlertConfirm();
    clearInterval(this.global.messageCountTimer);
  }

  async ngOnInit() {
    
    // const currentVersion = localStorage.getItem('appVersion') ?? '1.0.0';
    // localStorage.setItem('appVersion', currentVersion);
    // const headers = new HttpHeaders().set('Cache-Control', 'no-store, no-cache, must-revalidate');
    // this.httpClient
    //   .get<{ buildRevision: string }>('/config/config.json', { headers })
    //   .subscribe(config => {
    //     if (config.buildRevision !== currentVersion) {
    //       localStorage.setItem('appVersion', config.buildRevision);
    //       if (currentVersion != '1.0.0') {
    //         this.httpClient
    //           .get('', { headers, responseType: 'text' })
    //           .subscribe(() => window.location.reload());
    //       }
    //     }
    //   });

    this.global.apiURL = localStorage.apiURL;
    this.global.ssoApiURL = localStorage.ssoApiURL;
    this.global.appURL = localStorage.appURL;
    this.global.version = localStorage.version;
    this.global.accessKey = localStorage.accessKey;
    this.global.interval = localStorage.interval;
    this.global.CustomerName = localStorage.appName;
    this.global.firebaseConfig = localStorage.firebaseConfig;
    this.global.firstName = localStorage.FirstName;
    this.global.lastName = localStorage.LastName;

    if(window.innerWidth > 992){
      this.global.showCustomMenuButton = true;
      this.global.showSideMenu = true;
    }else{
      this.global.showCustomMenuButton = false;
      this.global.showSideMenu = false;
    }
    const url = new URL(location.href);
    this.domainUrl = url.searchParams.get('Domain'.toLowerCase());

    if (this.domainUrl) {
      console.log('setting ext url');
      localStorage.externalLink = true;
      localStorage.externalURL = url.pathname;
    }
    if (localStorage.locales) {
      this.global.locales = [];
      const loc = JSON.parse(localStorage.locales);
      this.global.localeLength = loc.length;
      const localesData = [];
      loc.forEach(el => {
        localesData.push({ key: el.localename, value: String(el.localeid) });
      });

      for (let i = 0; i < loc.length; i++) {
        if (localesData[i].value == '1033') {
          this.global.locales[i] = { ...localesData[i], LocaleIconUrl: 'flag-icon-gb' };
        }
        else if (localesData[i].value == '1043') {
          this.global.locales[i] = { ...localesData[i], LocaleIconUrl: 'flag-icon-nl' };
        }
        else if (localesData[i].value == '1036') {
          this.global.locales[i] = { ...localesData[i], LocaleIconUrl: 'flag-icon-fr' };
        }
        else if (localesData[i].value == '1031') {
          this.global.locales[i] = { ...localesData[i], LocaleIconUrl: 'flag-icon-de' };
        }
      }
    }

    if (localStorage.locale) {
      this.global.lang = localStorage.locale;
    } else {
      this.global.lang = '1033';
      localStorage.locale = this.global.lang;
    }

    this.loginPages = [
      {
        title: 'label-sendDiagnostic',
        url: '/senddiagnostic',
        icon: 'share'
      }
    ];

    this.homePages = [
      {
        title: 'Home',
        url: '/home',
        icon: 'home'
      }
    ];

    // const data: any = await this.httpClient.get('config/config.json', this.httpOptions).toPromise();
    // const currentApplicationVersion = data.buildRevision;
    // if (localStorage.appVersion && currentApplicationVersion != localStorage.appVersion) {
    //   localStorage.appVersion = currentApplicationVersion;
    //   this.onVersionChange();
    // } else {
    //   localStorage.appVersion = currentApplicationVersion;
    // }

    this.router.events.subscribe(async (e) => {
      if (e instanceof NavigationEnd) {
        // console.log("url",e.url);
        if(!e.url.includes('/remoteSupport') && !e.url.includes('/LinkAuthentication') && !e.url.includes("/EmaillinkAuthentication")){
          if (localStorage.authenticationKey && localStorage.authenticationKey != '') {
            this.translationService.getTranslation(this.global.lang);
            this._generalService.getMessageCountInterval();
          }
      
          if (localStorage.apiURL && localStorage.apiURL != 'undefined' && localStorage.apiURL == '') {
            this.global.apiURL = localStorage.apiURL;
          }
          if (localStorage.ssoApiURL && localStorage.ssoApiURL != 'undefined' && localStorage.ssoApiURL == '') {
            this.global.ssoApiURL = localStorage.ssoUrl;
          }
          if (localStorage.appURL && localStorage.appURL != 'undefined' && localStorage.appURL == '') {
            this.global.appURL = localStorage.appURL;
          }
      
          if (localStorage.authenticationKey != null && localStorage.userName != null && localStorage.userId != null && localStorage.authenticationKey.length > 0) {
      
            const data = await this._generalService.getSettings();
            localStorage.appSettings = JSON.stringify(data);
      
            for (let i = 0; i < data.length; i++) {
              if (data[i].SettingName.toLowerCase() == 'customercolor') {
                if (data[i].SettingValue) {
                  document.documentElement.style.setProperty('--ion-color-secondary', data[i].SettingValue);
                  document.documentElement.style.setProperty('--ion-color-primary', data[i].SettingValue);
                  localStorage.domainColor = data[i].SettingValue;
                  this.global.domainColor = localStorage.domainColor;
                } else {
                  document.documentElement.style.setProperty('--ion-color-secondary', '#bdd435');
                  document.documentElement.style.setProperty('--ion-color-primary', '#08378a');
                  localStorage.domainColor = '';
                  this.global.domainColor = localStorage.domainColor;
                }
              }
              if (data[i].SettingName.toLowerCase() == 'homescreenscantext') {
                if (data[i].SettingValue == 'true') {
                  this.global.enableVinScan = true;
                } else {
                  this.global.enableVinScan = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'homescreenscanqrcode') {
                if (data[i].SettingValue == 'true') {
                  this.global.enableQRCodeScan = true;
                } else {
                  this.global.enableQRCodeScan = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'homescreenrecentlist') {
                if (data[i].SettingValue == 'true') {
                  this.global.enableRecentList = true;
                } else {
                  this.global.enableRecentList = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'homescreensearchkeywords') {
                if (data[i].SettingValue == 'true') {
                  this.global.enableSearchKeyword = true;
                } else {
                  this.global.enableSearchKeyword = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'enableuserrating') {
                if (data[i].SettingValue == 'true') {
                  localStorage.enableRating = 'true';
                } else {
                  localStorage.enableRating = 'false';
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'enablequestionaire') {
                if (data[i].SettingValue == 'true') {
                  this.global.enableQuestionaire = true;
                } else {
                  this.global.enableQuestionaire = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'enablechecklists') {
                if (data[i].SettingValue == 'true') {
                  this.global.enableCheckLists = true;
                } else {
                  this.global.enableCheckLists = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'enablewritetag') {
                if (data[i].SettingValue == 'true') {
                  this.global.enableWriteTag = true;
                } else {
                  this.global.enableWriteTag = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'enabletaketest') {
                if (data[i].SettingValue == 'true') {
                  this.global.enableTakeTest = true;
                } else {
                  this.global.enableTakeTest = false;
                }
              }
      
              if (data[i].SettingName == 'HideTakeATestPWAMenu') {
                if (data[i].SettingValue == 'true') {
                  this.global.HideTakeATestPWAMenu = true;
                } else {
                  this.global.HideTakeATestPWAMenu = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'homescreeninfoblock') {
                if (data[i].SettingValue == 'true') {
                  this.global.enableInfoBlock = true;
                } else {
                  this.global.enableInfoBlock = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'enablereportincident') {
                if (data[i].SettingValue == 'true') {
                  this.global.enableReportIncident = true;
                } else {
                  this.global.enableReportIncident = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'enablemessages') {
                if (data[i].SettingValue == 'true') {
                  this.global.enableMessages = true;
                } else {
                  this.global.enableMessages = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'iframedpageurl') {
                this.global.enableIFramePageURL = true;
                if (data[i].SettingValue) {
                  this.global.IFramePageURL = data[i].SettingValue;
                } else {
                  this.global.IFramePageURL = '';
                }
              }
              if (data[i].SettingName.toLowerCase() == 'menuopeninfoblockurl') {
                this.global.enableMenuOpenInfoBlockURL = true;
                if (data[i].SettingValue) {
                  this.global.urlOfInfoBlock = data[i].SettingValue;
                } else {
                  this.global.urlOfInfoBlock = '';
                }
              }
              if (data[i].SettingName.toLowerCase() == 'pwalinkphonecalltomobile') {
                if (data[i].SettingValue == 'true') {
                  this.global.phoneCallToLinkMobile = true;
                } else {
                  this.global.phoneCallToLinkMobile = false;
                }
              }
      
              if (data[i].SettingName.toLowerCase() == 'hidelogoutbutton') {
                if (data[i].SettingValue == 'true') {
                  this.global.hideLogoutButton = true;
                } else {
                  this.global.hideLogoutButton = false;
                }
              }
              if (data[i].SettingName.toLowerCase() == 'enablescorm') {
                this.scormValue = data[i].SettingValue;
              }
              if (data[i].SettingName.toLowerCase() == 'enabletaketest') {
                this.testValue = data[i].SettingValue;
              }
              if (this.scormValue == 'true') {
                this.global.enableScorm = true;
              } else {
                this.global.enableScorm = false;
              }
      
            }
          }
        }
      }
    });
  
    
    console.log(this.global.enableMenuOpenInfoBlockURL);


  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: this.global.translate('label-applicationClose'),
      message: this.global.translate('label-closeAndLogout'),
      buttons: [
        {
          text: this.global.translate('label-no'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: this.global.translate('label-yes'),
          handler: () => {
            this.global.presentLoading();
            const params = {
              url: this.global.apiURL + '/Logout',
              requesttype: 'PUT',
              authenticationkey: localStorage.authenticationKey,
              params: {}
            };
            this._authService.doLogout(params).subscribe(
              (res) => {
                this.global.dismiss();
                this.global.clearSettings(false);
                console.log('Navigating to login page: User clicked the logout button.');
                this.router.navigate(['/login'], { replaceUrl: true });
              },
              (err) => {
                this.global.dismiss();
                this.global.clearSettings(false);
                console.log('Navigating to login page: Error while User clicked the logout button.',`error:${JSON.stringify(err)}`);
                this.router.navigate(['/login'], { replaceUrl: true });
              }
            ), () => {
              this.global.clearSettings(false);
              console.log('Navigating to login page: User clicked the logout button.');
              this.router.navigate(['/login'], { replaceUrl: true });
              this.global.dismiss();
            };

          }
        }
      ]
    });

    await alert.present();
  }

  navigate(url) {
    if (url === '/logout') {
      this.logout();
    }
    else {
      if (url === '/login'){
        console.log('Navigating to login domain page: navigate()');  
      }
      
      this.router.navigate([url]);
    }
  }

  sendDiagnostic() {
  }

  changeLanguage(event) {
    this.global.presentLoading();
    this.translationService.getTranslation(event)
      .then(
        async (data) => {
          try {
            const data = {
              LocaleId: this.global.lang
            };
            const res = await this._generalService.updateUserLocale(data, localStorage.userId);
            localStorage.locale = event;
            this.global.dismiss();
          } catch (error) {
            this.global.lang = localStorage.prevLocale;
            localStorage.removeItem('prevLocale');
            this.global.dismiss();
            if (error.status === 0) {
              this.global.presentAlert(this.global.translate('label-errorMessage'), this.global.translate('label-noInternet'));
            } else {
              this.global.presentAlert(this.global.translate('label-errorMessage'), this.global.translate('label-errorMessage'));
            }
          }
        }
      ).catch(
        (err) => {
          this.global.dismiss();
        }
      );
  }

  changeLocale() {
    localStorage.prevLocale = this.global.lang;
    this.select.open();
    this.setFlagLocale();
  }

  async askQuestion() {
    document.addEventListener('keydown', this.disableEnterKey);
    if(this.apiCallInProgress){
      return;
    }
    this.apiCallInProgress=true
    this.global.presentLoading();
    this._generalService.sendPushNotification().then(
      (res) => {
        const userQuestionId = res.UserQuestionId;
        if (res.QuestionaireCount && res.QuestionaireCount !== '0') {
          this.global.questionnaireCount = Number(res.QuestionaireCount);
        } else {
          this.global.questionnaireCount = 1;
        }
        this._generalService.getUserQuestions(userQuestionId, localStorage.locale).then(
          (obj) => {
            this.global.dismiss();
            obj.type = 'question';
            this.openModal(obj);
            this.apiCallInProgress=false;
          }
        ).catch(err => {
          console.log(err);
          this.global.dismiss();
          this.apiCallInProgress=false;
        });
      },
      (err) => {
        this.global.presentAlert('', err.error.message ? this.global.translate(err.error.message) : this.global.translate('label-questionNotLinked'));
        this.apiCallInProgress=false;
      }
    ).catch(
      (error) => {
        this.global.presentAlert('', error.error.message ? this.global.translate(error.error.message) : this.global.translate('label-questionNotLinked'));
        this.apiCallInProgress=false;
      }
    );

  }

  async takeTest() {
    document.addEventListener('keydown', this.disableEnterKey);
    if(this.apiCallInProgress){
      return;
    }
    this.apiCallInProgress=true
    this.global.presentLoading();
    this._generalService.startTest({}).then(
      (res) => {
        res.type = 'takeatest';
            this.openModal(res);
            this.global.dismiss();
            this.apiCallInProgress=false;
      },
    ).catch(
      (error) => {
        this.global.dismiss();
        this.apiCallInProgress=false;
        this.global.presentAlert('', error.error.message ? this.global.translate(error.error.message) : this.global.translate('label-questionNotLinked'));
      }
    );
  }

  disableEnterKey(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
  clearCache() {
  }

  // async requestPermission() {

  //     this.notificationService.requestPermission();

  // }

  async openModal(res) {
    const modal = await this.modalController.create({
      component: QuestionModalPage,
      backdropDismiss: false,
      cssClass: 'question-modal',
      componentProps: {
        params: res,
      }
    });

    modal.onDidDismiss().then((dataReturned) => {

    });

    return await modal.present();
  }

  setFlagLocale() {
    setTimeout(() => {
      const alertButtons = document.querySelectorAll('.customLocaleSelect button.alert-radio-button');
      if (alertButtons.length > 0) {
        alertButtons.forEach((button, index) => {
          button.querySelector('.alert-button-inner').insertAdjacentHTML('beforeend', '<span class=\'flag-icon \'></span>');
          button.querySelector('.flag-icon').className += this.global.locales[index].LocaleIconUrl;
        });
      } else {
        this.setFlagLocale();
      }
    }, 100);
  }

  // onVersionChange() {
  //   this.global.presentLoading();
  //   const params = {
  //     url: this.global.apiURL + '/Logout',
  //     requesttype: 'PUT',
  //     authenticationkey: localStorage.authenticationKey,
  //     params: {}
  //   };
  //   this._authService.doLogout(params).subscribe(
  //     (res) => {
  //       this.reloadPage(true);
  //     },
  //     (err) => {
  //       this.reloadPage(true);
  //     }
  //   ), () => {
  //     this.reloadPage(true);
  //   };
  // }

  // reloadPage(preserveExternalLink) {
  //   this.global.dismiss();
  //   this.global.clearSettings(preserveExternalLink);
  //   if (this.domainUrl && this.domainUrl.toLowerCase() !== localStorage.domain.toLowerCase()) {
  //     console.log('Navigating to login domain page: reloadPage()');  
  //     this.router.navigate(['login/domain'], { replaceUrl: true, queryParams: { d: this.domainUrl, reload: true } });
  //   } else {
      
  //     console.log('Navigating to login page: reloadPage().');
  //     this.router.navigate(['login'], { replaceUrl: true, queryParams: { reload: true } });
  //   }
  // }

  navigateToInfoBlock() {
    window.location.href = this.global.urlOfInfoBlock;
}

openInNewTab() {
  let webSettings =[];
 webSettings = JSON.parse(localStorage.getItem('appSettings'));

const iframePageURLSetting = webSettings.find(
  (setting) => setting.SettingName === 'IFramedPageURL'
);

const url = iframePageURLSetting.SettingValue;  window.open(url, '_blank');
}

openInfoBlock() {
  const url = this.global.urlOfInfoBlock ;
  window.location.href = url;
  }

}


