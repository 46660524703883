import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global.service';
import { GeneralService } from 'src/app/services/general.service';
import { CameraComponent } from './camera/camera.component';
import { TwilioSettingsComponent } from './twilio-settings/twilio-settings.component';
import { ParticipantsComponent } from './participants/participants.component';
import { createLocalAudioTrack, Room, LocalTrack, LocalVideoTrack, LocalAudioTrack, RemoteParticipant, Participant } from 'twilio-video';
import { Subscription } from 'rxjs';
import { VideochatService } from 'src/app/services/videochat.service';

@Component({
  selector: 'app-video-call-modal',
  templateUrl: './video-call-modal.component.html',
  styleUrls: ['./video-call-modal.component.scss'],
})
export class VideoCallModalComponent implements OnInit {
  @ViewChild('camera') camera: CameraComponent;
  @ViewChild('settings') settings: TwilioSettingsComponent;
  @ViewChild('participants') participants: ParticipantsComponent;
  videoCam: boolean = true;
  mic: boolean = true;
  volume: boolean = true;
  notificationMessage: any;
  roomName: string;
  usersList: Array<any> = [];
  infoBlock: Array<any> = [];
  activeUsers: Array<any> = [];
  display: string = "";
  activeRoom: Room;
  time: number = 0;
  interval;
  serviceData: string = "";
  private audio = new Audio();
  subscription: Subscription;
  lang: any;
  itemId: any;
  infoId: any;
  infoName: any;
  userSelected: string;
  timeOut: any;
  callReceived: boolean = false;
  calling: boolean = false;
  receiving: boolean = false;
  notificationClicked: string = '';
  closed: boolean = false;

  constructor(private _generalService: GeneralService,
    public global: GlobalService,
    private modalController: ModalController,
    private navParams: NavParams,
    private readonly videoChatService: VideochatService,) { }

  ngOnInit() {
    let data = this.navParams.data;
    if(data) {
      this.itemId = this.navParams.data.itemId;
      if(data && data.infoId) {
        this.infoId = data.infoId
      }
      if(data && data.infoName) {
        this.infoName = data.infoName
      }
    }
    this.roomName = Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 10); 
  }

  ngAfterViewInit(): void {
    if (localStorage.notificationData) {
      this.notificationMessage = JSON.parse(localStorage.notificationData);
      if (this.notificationMessage.InfoBlocks) {
        this.infoBlock = JSON.parse(this.notificationMessage.InfoBlocks);
      }
      localStorage.caller = this.notificationMessage.fromIdentity;
      this.notificationClicked = localStorage.notificationClicked;
      localStorage.removeItem('notificationClicked');
      localStorage.removeItem('notificationData');
    }

    if(this.notificationMessage && this.notificationMessage.type == 'calling' && this.notificationClicked == 'false') {
      this.global.startTimer('receiving');
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        if(!this.callReceived && !this.closed) {
          this.close();
        }
      }, 31000);
      this.receiving = true;
      this.calling = false;
      this.callReceived = false;
    } else {
      this.calling = true;
      this.receiving = false;
      this.callReceived = false;
      setTimeout(() => {
        this.onRoomChanged();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.global.checkBackURL = false;
    localStorage.twilioMenuEntered = false;
    this.callReceived = false;
    this.calling = false;
    this.receiving = false;
    this.roomName = "";
    localStorage.removeItem('caller');
    localStorage.removeItem('receiver');
    this.onLeaveRoom(true);
  }

  pickCall() {
    this.onRoomChanged();
    this.global.pauseTimer();
    this.updateStatus('false');
    this.callReceived = true;
    this.calling = false;
    this.receiving = false;
  }

  async close() {
    console.log("-------------------------call ended");
    this.closed = true;
    if(this.activeRoom) {
      this.activeRoom.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track));
    }
    this.global.pauseTimer();
    clearTimeout(this.timeOut);
    this.updateStatus('true');

    let obj;
    if (this.callReceived) {
      obj = {
        "Title": "Notify user",
        "Body": "End call",
        "RoomName": this.roomName,
        "Type": "endCall",
        "Identity": localStorage.caller ? localStorage.caller : localStorage.receiver  //add fromIdentity or to identity
      }
    } else if (this.receiving) {
      obj = {
        "Title": "Notify user",
        "Body": "End call",
        "RoomName": this.roomName,
        "Type": "reject"
      }
    } else {
      obj = {
        "Title": "Notify user",
        "Body": "End call",
        "RoomName": this.roomName,
        "Type": "declined"
      }
    }
    if (obj) {
      try {
        let res = await this._generalService.sendTwilioNotification(obj);
        localStorage.isTimerStop = false;
        await this.videoChatService.updateAvailableStatus("", "true");
      } catch (err) {
        console.log(err);
        this.global.pauseTimer();
      }
    }
    const onClosedData: string = "Success";
    this.modalController.dismiss(onClosedData);
  }

  async onLeaveRoom(check: boolean) {
    this.notificationMessage = "";
    this.infoBlock = [];
    this.global.pauseTimer();
    if (check == true) {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
        this.activeRoom = null;
      }

      const videoDevice = await this.settings.hidePreviewCamera();
      this.participants.clear();

    } else {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
        this.activeRoom = null;
      }
      this.notificationMessage = "";
      this.roomName = Math.random().toString(36).replace(/[^a-zA-Z]+/g, '').substr(0, 10);
      this.onRoomChanged();

      this.participants.clear();
    }

  }

  async onRoomChanged() {
    let data = {
      'roomName': this.notificationMessage ? this.notificationMessage.roomName ? this.notificationMessage.roomName : this.roomName : this.roomName
    }

    if (data.roomName) {
      this.roomName = data.roomName;
      localStorage.roomName = data.roomName;
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }

      this.camera.finalizePreview();
      const tracks = await this.settings.showPreviewCamera();

      this.activeRoom = await this.videoChatService.joinOrCreateRoom(data.roomName, tracks);
      if (!this.notificationMessage) {
        this.participants.initialize(this.activeRoom.participants);
        this.addParticipant();
      } else {
        this.participants.initialize(this.activeRoom.participants);
      }
      this.registerRoomEvents();
    }
  }

  private registerRoomEvents() {
    this.activeRoom
      .on('disconnected',
        (room: Room) => {
          room.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track))
          console.log("disconnected due to failure..")
          // this.global.presentAlert(this.translatedObj['label-error'], this.translatedObj['label-errorMessage'], this.translatedObj['label-unauthorize'], "");
        })
      .on('participantConnected',
        (participant: RemoteParticipant) => {
          console.log("participant connected..")
          this.participants.add(participant);
          this.participants.isCalling = false;
          this.participants.showHangupBtn = true;
          this.callReceived = true;
          this.global.pauseTimer();
          this.updateStatus("false");

        })
      .on('participantDisconnected',
        (participant: RemoteParticipant) => {
          this.participants.remove(participant);
          this.participants.showHangupBtn = false;
          this.global.pauseTimer();
          this.updateStatus("true");
        })
      .on('dominantSpeakerChanged',
        (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
  }

  private detachLocalTrack(track: LocalTrack) {
    if (this.isDetachable(track)) {
      track.detach().forEach(el => el.remove());
    }
  }

  private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
    return !!track
      && ((track as LocalAudioTrack).detach !== undefined
        || (track as LocalVideoTrack).detach !== undefined);
  }

  async addParticipant() {
    this.global.checkBackURL = false;
    this.global.startTimer("calling");
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      if(!this.callReceived && !this.closed) {
        this.close();
      }
    }, 31000);
    var obj = {
      "Title": "Notify user",
      "Body": "Please join the call",
      "RoomName": this.roomName,
      "Type": "calling",
      "ItemId": this.itemId,
      "InfoBlockId": this.infoId ? this.infoId : null,
      "InfoBlockName": this.infoName ? this.infoName : null
    }
    try {
      let res = await this._generalService.sendTwilioNotification(obj)
    } catch (err) {
      console.log(err);
      this.global.dismiss();
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.userSelected = "";
        if (err.status == 403) {          
          this.close();
          this.global.presentAlert(this.global.translate('label-errorMessage'), this.global.translate('label-noActiveUsers'));
        }
        else {
          this.close();
          this.global.presentAlert(this.global.translate('label-error'), this.global.translate('label-errorMessage'));
        }
      }, 5000);
    }     
  }

  async updateStatus(status) {
    try {
      let data = await this.videoChatService.updateAvailableStatus(this.roomName, status);
    } catch (err) {
      this.global.presentAlert(this.global.translate('label-error'), this.global.translate('label-errorMessage'));
    }
  }

  muteAudio() {
    this.global.mute(!this.volume);
  }

  async toogleVideo() {
    //this.settings.toggleVideo(this.videoCam);
    this.activeRoom.localParticipant.tracks.forEach(publication => 
      {
        if(publication.track.kind == 'video') {
          if(this.videoCam) {
            publication.track.enable();
          } else {
            publication.track.disable();
          }
        }
      })
  }

  toggleCamera() {
    let device = this.settings.devices.filter(x => x.deviceId != this.settings.video.selectedId);
    if(device.length != 0) {
      this.settings.onSettingsChanged(device[0]);
    }
    
  }

  toggleMic() {
    this.activeRoom.localParticipant.tracks.forEach(publication => 
      {
        if(publication.track.kind == 'audio') {
          if(this.mic) {
            publication.track.enable();
          } else {
            publication.track.disable();
          }
        }
      })
  }

}
