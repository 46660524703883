import { HelptextModalPageModule } from './shared/components/helptext-modal/helptext-modal.module';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { QuestionModalPageModule } from 'src/app/shared/components/question-modal/question-modal.module';
import { RatingModalPageModule } from 'src/app/shared/components/rating-modal/rating-modal.module';
import { MessageModalPageModule } from 'src/app/shared/components/message-modal/message-modal.module';
import { VideoCallModalComponent } from 'src/app/shared/components/video-call-modal/video-call-modal.component';
import { TwilioSettingsComponent } from 'src/app/shared/components/video-call-modal/twilio-settings/twilio-settings.component';
import { ParticipantsComponent } from 'src/app/shared/components/video-call-modal/participants/participants.component';
import { CameraComponent } from 'src/app/shared/components/video-call-modal/camera/camera.component';
import { DeviceSelectComponent } from 'src/app/shared/components/video-call-modal/device-select/device-select.component'
import { DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { SharedModule } from 'src/app/shared.module';
import { VideoPlayerComponent } from 'src/app/shared/components/video-player/video-player.component';
import { HttpClientModule, HttpClient, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './helpers/auth.interceptor';

// initialize app with empty config
const angularFireImport = AngularFireModule.initializeApp({});

// Filter the config from the providers:
angularFireImport.providers = angularFireImport.providers.filter(
  (provider: any) => provider.provide !== FIREBASE_OPTIONS
);

@NgModule({
  declarations: [
    AppComponent,
    VideoCallModalComponent,
    TwilioSettingsComponent,
    ParticipantsComponent,
    CameraComponent,
    DeviceSelectComponent,
    VideoPlayerComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({mode:'md'}),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    QuestionModalPageModule,
    RatingModalPageModule,
    MessageModalPageModule,
    TranslateModule.forRoot(),
    ServiceWorkerModule.register('/firebase-messaging-sw.js', { enabled: true}),
    angularFireImport,
    AngularFireMessagingModule,
    HelptextModalPageModule,
    SharedModule,
  ],
  providers: [
    AuthGuard,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      deps: [ HttpClient ],
      useFactory: (httpClient:HttpClient) => {
        return (): Promise<any> => {
          return new Promise<void>(async (resolve, reject) => {
            try {
              let httpOptions = {
                headers: new HttpHeaders({
                  'Cache-Control': 'no-cache',
                  'Pragma': 'no-cache',
                  'Content-Type':  'application/json'
                })
              }

              let data:any = await httpClient.get('config/config.json', httpOptions).toPromise();
              // comment if running locally
              if(data.defaultDomainName) {
                localStorage.setItem('defaultDomainName', data.defaultDomainName);
                localStorage.setItem('domain', data.defaultDomainName)
              }

              //comment if running in dev
              // if(data.defaultDomainName) {
              //   localStorage.setItem('defaultDomainName', 'stsdemo');
              //   localStorage.setItem('domain', 'stsdemo')
              // }

              localStorage.setItem('hsolDomainName', data.hsolDomainName);
              localStorage.apiURL = data.apiURL;
              localStorage.ssoApiURL = data.ssoApiURL;
              localStorage.appURL = data.appURL;
              localStorage.version = data.version;
              localStorage.accessKey = data.accessKey;
              localStorage.interval = data.interval;
              localStorage.CustomerName = data.appName;
              localStorage.firebaseConfig = data.firebaseConfig;
              resolve();
            } catch (error) {
              reject(error);
            }
          })
        }
      },
      // (
      //   (): Observable<any> => mainService.loadConfig()
      // ),
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
