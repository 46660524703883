import { GlobalService } from './global.service';
import { Platform } from '@ionic/angular';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ScormService } from './scorm.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  onEntryClick = new EventEmitter();

  collapseAllEntries() {
    this.onEntryClick.emit();
  }

  isShowLogo: boolean = false;

  isOauth: boolean = false;
  CustomerLogo: string = "";
  version: string = "";

  CustomerName: string = "";

  public db: any;


  constructor(private platform: Platform,
    private _httpClient: HttpClient,
    private global: GlobalService,
    private router: Router,
  ) {


  }
  callWebService(params: any): Observable<any> {
    let requestType = params.requestType;
    let authenticationKey = null;
    let loginSource = null;
    try {
      authenticationKey = params.authenticationkey;
      loginSource = params.XLoginSource;
    }
    catch (err) {
      console.log(err);
    }
    if (requestType === "GET") {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'X-Security-AuthKey': authenticationKey ? authenticationKey : '',
        'X-LoginSource': 'mobile'
      })
      return this._httpClient.get(params.url, { headers: headers }).pipe(map((res: any) => {
        return res;
      }),
        catchError(
          (err: Response) => this.handleError(err)
        ));
    }
    if (requestType === "GETALL") {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'X-Security-AuthKey': authenticationKey ? authenticationKey : '',
        'X-LoginSource': 'mobile'
      })
      return this._httpClient.get(params.url, { headers: headers }).pipe(map((res: any) => {
        return res;
      }),
        catchError(
          (err: Response) => this.handleError(err)
        ));
    }
    if (requestType === "GETFILE") {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'X-Security-AuthKey': authenticationKey ? authenticationKey : '',
        'X-LoginSource': 'mobile',
      })
      return this._httpClient.get(params.url, { headers: headers }).pipe(map((res: any) => {
        return res;
      }),
        catchError(
          (err: Response) => this.handleError(err)
        ));
    }
    if (requestType === "NoAuthKeyGET") {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json'
      })
      return this._httpClient.get(params.url, { headers: headers }).pipe(map((res: any) => {
        return res;
      }),
        catchError(
          (err: Response) => this.handleError(err)
        ));
    }
    else if (requestType === "POST") {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'X-Security-AuthKey': authenticationKey ? authenticationKey : '',
        'X-LoginSource': 'mobile'
      })
      return this._httpClient.post(params.url, params.params, { headers: headers }).pipe(map((res: any) => {
        return res;
      }),
        catchError(
          (err: Response) => this.handleError(err)
        ));
    }
    else if (requestType === "PUT") {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'X-Security-AuthKey': authenticationKey ? authenticationKey : '',
        'X-LoginSource': 'mobile'
      })
      return this._httpClient.put(params.url, params.params, { headers: headers }).pipe(map((res: any) => {
        return res;
      }),
        catchError(
          (err: Response) => this.handleError(err)
        ));
    }
    else if (requestType === "PATCH") {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'X-Security-AuthKey': authenticationKey ? authenticationKey : '',
        'X-LoginSource': 'mobile'
      })
      return this._httpClient.patch(params.url, params.params, { headers: headers }).pipe(map((res: any) => {
        return res;
      }),
        catchError(
          (err: Response) => this.handleError(err)
        ));
    }
    else if (requestType === "DELETE") {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'X-Security-AuthKey': authenticationKey ? authenticationKey : '',
        'X-LoginSource': 'mobile'
      })
      return this._httpClient.delete(params.url, { headers: headers }).pipe(map((res: any) => {
        return res;
      }),
        catchError(
          (err: Response) => this.handleError(err)
        ));
    }
  }

  syncgetAnswers(url: string, itemId: string): Promise<any> {
    let filterItemId: string = ((itemId != null) ? "itemId=" + encodeURI(itemId) : "");
    let params = {
      url: url + "/Answers?" + filterItemId,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getOAuthConfig(): Promise<any> {
    let params = {
      url: this.global.apiURL + "/OAuthConfig",
      requestType: "NoAuthKeyGET"
    }
    return this.callWebService(params).toPromise();
  }

  getSettings(): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Settings",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }


  getTranslations(lang): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Translations?locale=" + lang + "&modified=" + '&isTranslationCode=1',
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getDomainTranslations(domainName, lang): Promise<any> {
    let apiURL = this.global.apiURL ? this.global.apiURL : localStorage.apiURL ? localStorage.apiURL : '';
    let params = {
      url: apiURL + "/TranslationsForDomain/" + domainName +"?locale=" + lang + "&modified=&isTranslationCode=1",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey ? localStorage.authenticationKey: null,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getSettingLogo(): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Setting/Logo",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getItems(search, tileView,page, locale): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Items?isTile="+ tileView +"&page=" + page +"&pageSize=" + "60" + "&search=" + search + "&localeid=" + locale,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  updateItem(model): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Items",
      requestType: "PUT",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: model
    }
    return this.callWebService(params).toPromise();
  }

  getItemdetail(id: string, locale: string): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Items/" + id + "?localeid=" + locale,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }


  getFlexFieldTypes(): Promise<any> {
    let params = {
      url: this.global.apiURL + "/FlexFieldTypes",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getFlexFieldDefinitions(id: string): Promise<any> {
    let params = {
      url: this.global.apiURL + "/FlexFieldDefinitions/" + id,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getItemImage(id: string): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Items/" + id + "/Image",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getAnswers(id: string, locale): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Items/" + id  +"/Answers"+ "?localeId=" + locale,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getAnswer(id: string): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Answers/" + id,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  async getAnswerFile(id: string): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Answers/" + id + "/File",
      requestType: "GETFILE",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getAnswerTypes(): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Answer/Types",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey
    }
    return this.callWebService(params).toPromise();
  }

  increaseKnowledgeTestCount(knowledgeTestId: string, increaseCount): Promise<any> {
    let params = {
      url: this.global.apiURL + "/KnowledgeTests/" + knowledgeTestId + "/IncreaseKnowledgeTestCount/?IncreaseCount="+increaseCount,
      requestType: "PATCH",
      authenticationkey: localStorage.authenticationKey
    };
    return this.callWebService(params).toPromise();
  }

  getBeacons(): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Beacons",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getCheckList(id, lang) {
    let params = {
      url: this.global.apiURL + "/FlexFieldDefinitions?sort=&isCheckList=1&page=1&pageSize=&modified=&itemId=" + id + "&localeId=",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getcheckListdetail(id) {
    let params = {
      url: this.global.apiURL + "/FlexFieldDefinitions/" + id + "?search=&isChecklist=1",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  saveCheckList(model): Promise<any> {
    let params = {
      url: this.global.apiURL + "/CheckListData",
      requestType: "POST",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: model
    }
    return this.callWebService(params).toPromise();
  }

  getUserCheckList() {
    let params = {
      url: this.global.apiURL + "/CheckListData?modifiedUserId=&itemCheckListId=&locale=",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getcheckListdata(id) {
    let params = {
      url: this.global.apiURL + "/CheckListData/" + id,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  deleteCheckData(id) {
    let params = {
      url: this.global.apiURL + "/CheckListData/" + id,
      requestType: "DELETE",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getItemPerScanText(text, locale) {
    let params = {
      url: this.global.apiURL + "/ItemsPerScanText?scanText=" + text + "&localeid=" + locale,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  syncgetItemImage(url: string, itemId: string): Promise<any> {
    let params = {
      url: url + "/Items/" + itemId + "/image",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getUserQuestions(id, localeId): Promise<any> {
    let params = {
      url: this.global.apiURL + "/UserQuestions_v2/" + id + "?localeId=" + localeId,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  sendPushNotification(): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Questionaire",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  updateToken(token): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Users",
      requestType: "PATCH",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: token
    }
    return this.callWebService(params).toPromise();
  }

  updateUserQuestions(id, obj): Promise<any> {
    let params = {
      url: this.global.apiURL + "/UserQuestions_v2/" + id,
      requestType: "POST",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: obj
    }
    return this.callWebService(params).toPromise();
  }

  saveReview(obj): Promise<any> {
    let params = {
      url: this.global.apiURL + "/UserRatings",
      requestType: "POST",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: obj
    }
    return this.callWebService(params).toPromise();
  }

  syncgetFlexFieldDefinitions(url: string, modifiedDate: string): Promise<any> {
    let filterDate: string = ((modifiedDate != null) ? "modified=" + encodeURI(modifiedDate) : "");
    let params = {
      url: url + "/FlexFieldDefinitions?" + filterDate,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  syncgetFlexFieldTypes(url: string, modifiedDate: string): Promise<any> {
    let filterDate: string = ((modifiedDate != null) ? "modified=" + encodeURI(modifiedDate) : "");
    let params = {
      url: url + "/FlexFieldTypes?" + filterDate,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  //TODO: check if not used anywhere !!!!
  syncgetItemThumbnail(url: string, itemId: string): Promise<any> {
    let params = {
      url: url + "/Items/" + itemId + "/thumbnail",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  syncgetLocations(url: string): Promise<any> {
    let params = {
      url: url + "/Locations",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  openBrowser(urlPath, target, location) {
    try {

    } catch (error) {
      //logWriter.log("Error..openBrowser:" + error);
    }
  }

  private handleError(error: Response) {
    console.log(error);
    this.global.dismiss();
    if (error.status == 0 && error["name"] == "TimeoutError") {
      // this.global.presentAlert(this.translatedObj['label-errorMessage'], this.translatedObj['label-sessionTimeout']);
      //this.global.log("ERROR", "Session timeout", error["message"]);
    } else if (error.status == 0) {
      // this.global.presentAlert(this.translatedObj['label-errorMessage'], this.translatedObj['label-noInternet']);
    }
    if (error.status == 401) {
      //this.global.log("ERROR", "Unauthorized ", 'key expired.');
      localStorage.authenticationKey = '';
      this.global.clearSettings(true);
      console.log('Navigating to login page: User not authorized.',`error:${JSON.stringify(error)}`);
      this.router.navigate(['/login']);
      //this.global.presentAlert(this.translatedObj['label-errorMessage'], this.translatedObj['label-unauthorize']);
    }
    if (error.status == 403) {
      this.global.presentAlert(this.global.translate('label-errorMessage'), error['error'].message ? error['error'].message : this.global.translate('error-forbidden'));
    }
    if (error.status == 410) {
      this.global.presentAlert(this.global.translate('label-errorMessage'), error['error'].message ? error['error'].message : this.global.translate('error-linkUsed'));
    }
    return throwError(error);
  }

  //twilio-video
  getTwilioToken() {
    let params = {
      url: this.global.apiURL + "/Twilio/Token",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  registerUser(model): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Twilio/Register",
      requestType: "POST",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: model
    }
    return this.callWebService(params).toPromise();
  }

  sendTwilioNotification(model): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Twilio/Notification",
      requestType: "POST",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: model
    }
    return this.callWebService(params).toPromise();
  }

  updateAvailableStatus(data, isAvailable): Promise<any> {
    var obj = {
      "RoomName": data
    }
    if (isAvailable == "true") {
      let params = {
        url: this.global.apiURL + "/Twilio/Users/Disconnect",
        requestType: "PATCH",
        authenticationkey: localStorage.authenticationKey,
        XLoginSource: "mobile",
        params: obj
      }
      return this.callWebService(params).toPromise();

    } else {
      let params = {
        url: this.global.apiURL + "/Twilio/Users/Connect",
        requestType: "PATCH",
        authenticationkey: localStorage.authenticationKey,
        XLoginSource: "mobile",
        params: obj
      }
      return this.callWebService(params).toPromise();

    }

  }

  updateMessage(id): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Messages/" + id,
      requestType: "PATCH",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: ""
    }
    return this.callWebService(params).toPromise();
  }

  acknowledgeUser(id): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Messages/" + id + "?status=acknowledged",
      requestType: "PATCH",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: ""
    }
    return this.callWebService(params).toPromise();
  }

  getMessageDetail(id, localeId): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Messages/" + id + "?localeId=" + localeId,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getMessageList() {
    let params = {
      url: this.global.apiURL + "/Messages",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getMessageCount(): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Messages/Count",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  disconnectUser(obj): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Twilio/Users/Disconnect",
      requestType: "PATCH",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: obj
    }
    return this.callWebService(params).toPromise();
  }

  connectUser(obj): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Twilio/Users/Connect",
      requestType: "PATCH",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: obj
    }
    return this.callWebService(params).toPromise();
  }

  getDomainSetting(domain): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Domains/" + domain + "/Setting",
      requestType: "GET",
      authenticationkey: '',
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getMessageCountInterval() {
    clearTimeout(this.global.messageCountTimer);
    this.global.messageCountTimer = setInterval(() => {
      this.getMessageCount().then(
        (data) => {
          if (data) {
            localStorage.setItem('messageUnseenCount', data.count ? data.count : 0);
            this.global.messageUnseenCount = data.count ? data.count : 0
          }
        }
      )
    }, 10000);

  }
  getInformationData(localeId) {
    let params = {
      url: this.global.apiURL + "/DashboardInfos/User" + "?localeId=" + localeId,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  takeTest(obj): Promise<any> {
    let params = {
      url: this.global.apiURL + "/KnowledgeTests",
      requestType: "POST",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: obj
    }
    return this.callWebService(params).toPromise();
  }
  startTest(obj): Promise<any> {
    let params = {
      url: this.global.apiURL + "/KnowledgeTests"+ "/Start",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: obj
    }
    return this.callWebService(params).toPromise();
  }

  getKnowledgeTestQuestion(id, localeId): Promise<any> {
    let params = {
      url: this.global.apiURL + "/KnowledgeTestQuestions_v2/" + id + "?localeId=" + localeId,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  updateKnowledgeTestQuestion(id, obj): Promise<any> {
    let params = {
      url: this.global.apiURL + "/KnowledgeTestQuestions_v2/" + id,
      requestType: "PUT",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: obj
    }
    return this.callWebService(params).toPromise();
  }

  getUserTestResult(id): Promise<any> {
    let params = {
      url: this.global.apiURL + "/KnowledgeTestQuestions_v2/" + id + "/Results",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  reportIncident(model): Promise<any> {
    let params = {
      url: this.global.apiURL + "/IncidentReports",
      requestType: "POST",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: model
    }
    return this.callWebService(params).toPromise();
  }

  loginLink(model): Promise<any> {
    let params = {
      url: this.global.apiURL + "/EmaillinkAuthentication",
      requestType: "POST",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: model
    }
    return this.callWebService(params).toPromise();
  }
  loginCode(model): Promise<any> {
    let params = {
      url: this.global.apiURL + "/LoginCodeAuthentication/:loginCode",
      requestType: "POST",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: model
    }
    return this.callWebService(params).toPromise();
  }


  emailLinkAuthentication(id, domainName): Promise<any> {
    let params = {
      url: this.global.apiURL + "/EmaillinkAuthentication/" + id + "?domainname=" + domainName,
      requestType: "PUT",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: ""
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept': 'application/json',
      'X-Security-AuthKey': params.authenticationkey ? params.authenticationkey : '',
      'X-LoginSource': 'mobile'
    })
    return this._httpClient.put(params.url, params.params, { headers: headers }).toPromise();
  }

  LoginCodeAuthentication(id, domainName): Promise<any> {
    let params = {
      url: this.global.apiURL + "/LoginCodeAuthentication/" + id + "?domainname=" + domainName,
      requestType: "PUT",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: ""
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept': 'application/json',
      'X-Security-AuthKey': params.authenticationkey ? params.authenticationkey : '',
      'X-LoginSource': 'mobile'
    })
    return this._httpClient.put(params.url, params.params, { headers: headers }).toPromise();
  }

  getUserIncident(locale): Promise<any> {
    let params = {
      url: this.global.apiURL + "/MyIncidents?localeId=" + locale,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getUserScorm(locale): Promise<any> {
    let params = {
      url: this.global.apiURL + "/MyScorms?localeId=" + locale,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getUserPhishingReport(locale): Promise<any> {
    let params = {
      url: this.global.apiURL + "/MyPhishingResults?localeId=" + locale,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  getHelpText(): Promise<any> {
    let params = {
      url: this.global.apiURL + "/HelpTexts",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  verify2faLinkedSend(data): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Reset2Fa",
      requestType: "POST",
      authenticationkey: '',
      XLoginSource: "mobile",
      params: data
    }
    return this.callWebService(params).toPromise();
  }

  ping(authcode): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Ping",
      requestType: "GET",
      authenticationkey: authcode,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  async getDecisionTree(query): Promise<any> {
    let params = {
      url: query,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }

  saveDecisionTreeAnswer(id, data): Promise<any> {
    let params = {
      url: this.global.apiURL + "/UserDecisionTrees/" + id + "/QuestionAnswers",
      requestType: "POST",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: data
    }
    return this.callWebService(params).toPromise();
  }
  updateUserLocale(data, userId): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Users/" + userId + '/Locale',
      requestType: "PATCH",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: data
    }
    return this.callWebService(params).toPromise();
  }

  getSecretKey(id): Promise<any> {
    let params = {
      url: this.global.apiURL + `/Notifications/${id}`,
      requestType: "GET",
      authenticationkey: "",
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }


  getScormDetails(id, answerId, localeid): Promise<any> {
    let params = {
      url: this.global.apiURL + `/Scorms/${id}?answerId=${answerId}&localeId=${localeid}`,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
    }
    return this.callWebService(params).toPromise();
  }
  getScormDetail(id, localeid): Promise<any> {
    let params = {
      url: this.global.apiURL + `/Scorms/${id}?localeId=${localeid}`,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
    }
    return this.callWebService(params).toPromise();
  }

  updateAnswerStatus(id, data) {
    let params = {
      url: this.global.apiURL + "/answers/" + id + "/status",
      requestType: "PUT",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: data
    }
    return this.callWebService(params).toPromise();
  }
  updateScormCmi(id, data) {
    let params = {
      url: this.global.apiURL + "/answers/" + id + "/scorm-cmi",
      requestType: "PUT",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",
      params: data
    }
    return this.callWebService(params).toPromise();
  }

  getTestReport(locale): Promise<any> {
    let params = {
      url: this.global.apiURL + "/MyKnowledgeTests?localeId=" + locale,
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile"
    }
    return this.callWebService(params).toPromise();
  }
  supportUserLogin(id): Promise<any> {
    let params = {
      url: this.global.apiURL + "/Login/" + id,
      requestType: "POST",
      authenticationkey: localStorage.authenticationKey,
      XLoginSource: "mobile",

    }
    return this.callWebService(params).toPromise();
  }
  getQuestionReports(): Promise<any>{
    let params = {
      url: this.global.apiURL + "/MyUserQuestionsReport",
      requestType: "GET",
      authenticationkey: localStorage.authenticationKey,      
      XLoginSource: "mobile",

    }
    return this.callWebService(params).toPromise();
  }

}




