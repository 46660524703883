import { GlobalService } from 'src/app/services/global.service';
import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _authService: AuthService, private router: Router, public global: GlobalService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let domainUrl = next.queryParams ? next.queryParams.Domain ? next.queryParams.Domain : '' : '';
    let menuItems = localStorage.MenuItems ? JSON.parse(localStorage.MenuItems) : [];

    if (!domainUrl) {
      let urlWithDomain = location.href.slice(location.href.lastIndexOf('/') + 1);
      if (urlWithDomain.indexOf('Domain=') == 0) {
        domainUrl = urlWithDomain.replace('Domain=', '');
      }
    }
    if (domainUrl) {
      localStorage.externalLink = true;
      localStorage.externalURL = state.url;
    }
    if (!localStorage.authenticationKey || localStorage.authenticationKey == '' || localStorage.authenticationKey == 'undefined') {
      if (!localStorage.domain || localStorage.domain == '' || localStorage.domain == "undefined" || localStorage.domain == "null") {
        localStorage.domain = '';
      }
      if (domainUrl && domainUrl.toLowerCase() != localStorage.domain.toLowerCase()) {
        console.log('Navigating to login domain page: canActivate()');
        this.router.navigate(['login/domain'], { replaceUrl: true, queryParams: { d: domainUrl } });
      } else {
        console.log('Navigating to login page: canActivate()');
        this.router.navigate(['login'], { replaceUrl: true });
      }
      return false;

    } else {
      if (domainUrl && domainUrl.toLowerCase() != localStorage.domain.toLowerCase()) {
        console.log('Navigating to login domain page: canActivate()');
        this.router.navigate(['login/domain'], { replaceUrl: true, queryParams: { d: domainUrl } });
        return false;
      } else {
          return true;
        }

    }
  }
}
