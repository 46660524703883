import { CanActivate } from '@angular/router';
/* eslint-disable max-len */
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { LoggedInAccessGuard } from 'src/app/shared/guards/logged-in-access.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DomainGuardGuard } from './shared/guards/domain-guard.guard';


const routes: Routes = [
  { path: '', pathMatch: 'full' , redirectTo: localStorage.getItem('isLogin') ? 'home' : '**'},
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule), canActivate: [LoggedInAccessGuard] },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule), canActivate: [AuthGuard] },
  { path: 'item/:id', loadChildren: () => import('./item-detail/item-detail.module').then(m => m.ItemDetailPageModule), canActivate: [AuthGuard] },
  { path: 'item/:id/:domainId', loadChildren: () => import('./item-detail/item-detail.module').then(m => m.ItemDetailPageModule), canActivate: [AuthGuard] },
  { path: 'answer/:itemid/:ansid', loadChildren: () => import('./answerdetail/answerdetail.module').then(m => m.AnswerdetailPageModule), canActivate: [AuthGuard] },
  { path: 'checkList', loadChildren: () => import('./checkList/checkList.module').then(m => m.CheckListPageModule), canActivate: [AuthGuard] },
  { path: 'checkList-detail/:id', loadChildren: () => import('./checkList-detail/checkList-detail.module').then(m => m.CheckListDetailPageModule), canActivate: [AuthGuard] },
  { path: 'checkList-data/:id', loadChildren: () => import('./checkList-data/checkList-data.module').then(m => m.CheckListDataPageModule), canActivate: [AuthGuard] },
  { path: 'message', loadChildren: () => import('./message/message.module').then(m => m.MessagePageModule), canActivate: [AuthGuard] },
  { path: 'message-details/:id', loadChildren: () => import('./message/message-details/message-details.module').then(m => m.MessageDetailsPageModule), canActivate: [AuthGuard] },
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'forgot-password/:id', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule) },
  { path: 'login/domain', loadChildren: () => import('./login-domain/login-domain.module').then(m => m.LoginDomainPageModule), canActivate: [DomainGuardGuard] },
  { path: 'oauth', loadChildren: () => import('./sso-callback/sso-callback.module').then(m => m.SsoCallbackPageModule) },
  { path: 'report-incident', loadChildren: () => import('./report-incident/report-incident.module').then(m => m.ReportIncidentPageModule) },
  // { path: 'report-incident-iframe',loadChildren: () => import('./report-incident-iframe/report-incident-iframe.module').then( m => m.ReportIncidentIframePageModule), canActivate: [AuthGuard]},
  { path: 'EmaillinkAuthentication/:id', loadChildren: () => import('./login-email-link/login-email-callback/login-email-callback.module').then(m => m.LoginEmailCallbackModule) },
  { path: 'logintoken', loadChildren: () => import('./login-email-link/login-email-callback/login-email-callback.module').then(m => m.LoginEmailCallbackModule) },
  { path: 'login-success', loadChildren: () => import('./login-email-link/login-success/login-success.module').then(m => m.LoginSuccessPageModule), canActivate: [LoggedInAccessGuard] },
  { path: 'login-invalid', loadChildren: () => import('./login-email-link/login-invalid-page/login-invalid.module').then(m => m.LoginInvalidPageModule), canActivate: [LoggedInAccessGuard] },
  { path: 'questionnaire/:id', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule), canActivate: [AuthGuard] },
  { path: 'knowledgetests/:id', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule), canActivate: [AuthGuard] },
  { path: 'knowledgetests/:id/:domain', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule), canActivate: [AuthGuard] },
  { path: 'my-incidents', loadChildren: () => import('./my-incident/my-incident.module').then(m => m.MyIncidentPageModule), canActivate: [AuthGuard] },
  { path: 'user/invitation/:id', loadChildren: () => import('./activate/activate.module').then(m => m.ActivatePageModule) },
  { path: 'loginverification', loadChildren: () => import('./otpverification/otpverification.module').then(m => m.OtpverificationPageModule) },
  { path: 'setup2fa/:id/:username', loadChildren: () => import('./otpverification/intermediate2-fa/intermediate2-fa.module').then(m => m.Intermediate2FAPageModule) },
  { path: 'videoplayer/:id', loadChildren: () => import('./vimeo-video-player/vimeo-video-player.module').then(m => m.VimeoVideoPlayerPageModule) },
  { path: 'my-results', loadChildren: () => import('./my-scorm/my-scorm.module').then(m => m.MyScormPageModule), canActivate: [AuthGuard] },
  { path: 'login-email-link', loadChildren: () => import('./login-email-link/login-email-link.module').then(m => m.LoginEmailLinkPageModule), canActivate: [LoggedInAccessGuard] },
  { path: 'remoteSupport', loadChildren: () => import('./supportuser/supportuser.module').then( m => m.SupportuserModule),},
  { path: 'hsol',loadChildren: () => import('./hsol/hsol.module').then( m => m.HsolPageModule),},
  { path: 'LinkAuthentication/:id',loadChildren: () => import('./login-email-link/login-email-authentication/login-email-authentication.module').then( m => m.LoginEmailAuthenticationPageModule)},
  { path: '**', pathMatch: 'full', redirectTo: localStorage.getItem('domain') ? 'login' : 'login/domain', },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
